import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import C from '@teamenki/common-constants';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import PageLoader from './PageLoader';
import { auth as authDuck } from '../redux/ducks';

class AuthorizedRoute extends PureComponent {
  static propTypes = {
    authStatus: PropTypes.string.isRequired,
    fetchMe: PropTypes.func.isRequired,
    component: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
  };

  static defaultProps = {
    isLoading: false,
  };

  componentDidMount() {
    this.props.fetchMe();
  }

  static renderBasedOnAuth({ Component, authStatus, isLoading, props }) {
    if (isLoading) {
      NProgress.start();
      return <PageLoader />;
    }

    NProgress.done();

    if (authStatus === C.USER.STATUS.LOGGEDIN) {
      return <Component {...props} />;
    }

    if (authStatus === C.USER.STATUS.UNAUTHORIZED) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: {
              from: props.location,
              redirectedBecauseUnauthorized: true,
            },
          }}
        />
      );
    }

    return null;
  }

  render() {
    const { component: Component, authStatus, isLoading, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={props =>
          AuthorizedRoute.renderBasedOnAuth({
            Component,
            authStatus,
            isLoading,
            props,
          })
        }
      />
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  authStatus: auth.status,
  isLoading: auth.meta.fetchMe.isLoading,
});

export default connect(
  mapStateToProps,
  authDuck.actionCreators
)(AuthorizedRoute);
