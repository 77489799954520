import C from '@teamenki/common-constants';

const { USER } = C;

export const name = 'logout';

export const cases = {
  creator() {
    return {
      meta: {
        promise: {
          method: 'POST',
          url: '/auth/logout',
          removeToken: true,
          authenticated: true,
        },
        redirectDirectly: { clearHistory: true, location: '/' },
        reset: true,
      },
    };
  },
  reducer(state) {
    return {
      ...state,
      status: USER.STATUS.UNAUTHORIZED,
      me: null,
    };
  },
};
