import * as validators from '@teamenki/common-validators';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Edit, TrashAlt as Trash } from 'styled-icons/fa-regular';
import { organization as organizationDuck } from '../../redux/ducks';
import { Card, Button } from '../styles';
import { RadioButtonGroup } from '../RadioButton';
import * as PrivateContent from './PrivateContent';
import ManageSlackTeams from './ManageSlackTeams';

// TODO get these from C
const orgMemberRoles = [
  {
    value: 'ADMIN',
    label: 'Maintainer',
  },
  {
    value: 'DEFAULT',
    label: 'Member',
  },
];

class Organization extends Component {
  static propTypes = {
    organization: PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      // TODO: fix data - not all orgs have this field in the DB
      isPublic: PropTypes.bool,
      members: PropTypes.arrayOf(
        PropTypes.shape({
          role: PropTypes.string.isRequired,
          // TODO: joined arrives at empty date from backend
          // joined: PropTypes.instanceOf(Date).isRequired,
          user: PropTypes.shape({
            username: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired,
            mail: PropTypes.string.isRequired,
          }),
        })
      ),
      repo: PropTypes.shape({
        owner: PropTypes.shape({
          name: PropTypes.string.isRequired,
        }).isRequired,
        name: PropTypes.string.isRequired,
        secret: PropTypes.string.isRequired,
      }),
    }).isRequired,
    leaveOrg: PropTypes.func.isRequired,
    userId: PropTypes.string.isRequired,
    removeMemberFromOrg: PropTypes.func.isRequired,
    inviteMemberToOrg: PropTypes.func.isRequired,
    changeMemberOrgRole: PropTypes.func.isRequired,
    isLoadingOrg: PropTypes.bool,
    shouldUpdateOrg: PropTypes.bool.isRequired,
    fetchOrg: PropTypes.func.isRequired,
    updateLastAccessedOrg: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isLoadingOrg: false,
  };

  state = {
    isModalOpen: false,
    modalMember: null,
    modalMemberNewRole: null,
    emailToInvite: '',
  };

  componentDidMount() {
    this.props.updateLastAccessedOrg({ id: this.props.organization.id });
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.shouldUpdateOrg && this.props.shouldUpdateOrg) {
      this.props.fetchOrg({ orgId: this.props.organization.id });
    }
  }

  canManageOrg = () => {
    const userId = this.props.userId;
    const userInOrg = this.props.organization.members.find(
      ({ user: { id } }) => userId === id
    );
    return userInOrg ? userInOrg.role === 'ADMIN' : false;
  };

  handleLeaveOrganization = e => {
    e.preventDefault();
    this.props.leaveOrg({ orgId: this.props.organization.id });
  };

  handleRemoveUserFromOrganization = (e, userId) => {
    e.preventDefault();
    this.props.removeMemberFromOrg({
      orgId: this.props.organization.id,
      memberId: userId,
    });
  };

  handleInviteUserToOrganization = e => {
    e.preventDefault();
    this.props.inviteMemberToOrg({
      orgId: this.props.organization.id,
      email: this.state.emailToInvite,
    });
  };

  handleEmailToInvite = ({ target: { value } }) =>
    this.setState({
      emailToInvite: value,
    });

  handleOpenRoleChangeModal = member => {
    this.setState({
      isModalOpen: true,
      modalMember: member,
      modalMemberNewRole: member.role,
    });
  };

  handleCloseRoleChangeModal = () => {
    this.setState({
      isModalOpen: false,
      modalMember: null,
      modalMemberNewRole: null,
    });
  };

  handleMemberPotentialRoleChange = potentialRole => {
    this.setState({
      modalMemberNewRole: potentialRole,
    });
  };

  handleMemberRoleChange = memberId => {
    this.props.changeMemberOrgRole({
      organizationId: this.props.organization.id,
      memberId,
      role: this.state.modalMemberNewRole,
    });
  };

  renderRoleChangeModal() {
    if (!this.state.isModalOpen) {
      return null;
    }

    const modalStyle = {
      zIndex: 1,
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#ffffff',
      padding: '10px',
      boxShadow: '0px 0px 5px 1px #000000',
    };

    const memberCurrentRoleIndex = orgMemberRoles.findIndex(
      ({ value }) => this.state.modalMember.role === value
    );

    return (
      <div style={modalStyle}>
        <Button onClick={this.handleCloseRoleChangeModal}>Close Modal</Button>
        <h3> Change the role for {this.state.modalMember.user.username} </h3>
        <p>Current role is {orgMemberRoles[memberCurrentRoleIndex].label}</p>
        <RadioButtonGroup
          items={orgMemberRoles}
          checkedIndex={memberCurrentRoleIndex}
          onChange={this.handleMemberPotentialRoleChange}
        />
        <Button
          disabled={
            this.state.modalMemberNewRole ===
            orgMemberRoles[memberCurrentRoleIndex].value
          }
          onClick={() =>
            this.handleMemberRoleChange(this.state.modalMember.user.id)
          }
        >
          Change Role
        </Button>
      </div>
    );
  }

  renderOrganizationMembers = () => (
    <div>
      {this.canManageOrg() && (
        <>
          <div>
            <Button
              disabled={
                !validators.email.validate(this.state.emailToInvite).valid
              }
              onClick={this.handleInviteUserToOrganization}
            >
              Invite new member
            </Button>
            <input
              type="email"
              placeholder="email"
              value={this.state.emailToInvite}
              onChange={this.handleEmailToInvite}
            />
          </div>
          <hr />
        </>
      )}
      <h4>Current Members</h4>
      <table>
        <thead>
          <th>Username</th>
          {this.canManageOrg() && <th>Actions</th>}
        </thead>
        <tbody>
          {this.props.organization.members.map(member => (
            <tr key={`organization-user-${member.user.username}`}>
              <td>{member.user.username}</td>
              <td>
                {this.canManageOrg() && member.user.id !== this.props.userId ? (
                  <>
                    <Button
                      title="Remove member"
                      onClick={e =>
                        this.handleRemoveUserFromOrganization(e, member.user.id)
                      }
                    >
                      <Trash size="15" />
                    </Button>
                    <Button
                      onClick={() => this.handleOpenRoleChangeModal(member)}
                    >
                      <Edit size="15" />
                    </Button>
                  </>
                ) : (
                  'N/A'
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  renderOrganizationSettings = () => (
    <div>
      <div>
        <Card>
          <p>
            This organization is
            {this.props.organization.isPublic ? ' public' : ' private'}
          </p>
          <Button onClick={this.handleLeaveOrganization}>
            Leave organization
          </Button>
          <hr />
          {this.canManageOrg() && (
            <>
              <PrivateContent.Manage organization={this.props.organization} />
              <hr />
              <ManageSlackTeams organization={this.props.organization} />
            </>
          )}
        </Card>
      </div>
    </div>
  );

  render() {
    if (this.props.isLoadingOrg) {
      return <p> Loading the org </p>;
    }
    return (
      <div>
        {this.renderRoleChangeModal()}
        <div>
          <h3>{this.props.organization.name}</h3>
          {this.renderOrganizationSettings()}
          <hr />
          {this.renderOrganizationMembers()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, organization }) => ({
  userId: auth.me.id,
  isLoadingOrg: organization.meta.fetchOrg.isLoading,
  shouldUpdateOrg: organization.shouldUpdateOrg,
});

export default connect(
  mapStateToProps,
  organizationDuck.actionCreators
)(Organization);
