import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import { background } from './modifiers';

const StyledAlert = styled.div`
  background-color: ${background.color};
  border-radius: 2px;
  color: ${prop('theme.colors.light')};
  padding: 10px;
`;

export default function Alert(props) {
  return <StyledAlert {...props}>{props.children}</StyledAlert>;
}

Alert.propTypes = {
  children: PropTypes.node.isRequired,
};
