import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';
import { PersistGate } from 'redux-persist/lib/integration/react';
import 'loaders.css/loaders.css';
import { ThemeProvider } from 'styled-components';
import Auth from './Auth';
import AuthProviderCallback from './AuthProviderCallback';
import OrganizationsDashboard from './OrganizationsDashboard';
import Curriculum from './Curriculum';

import {
  NotFound,
  AuthorizedRoute,
  DefaultLayout,
  Feedback,
  Home,
  ImageUpload,
  Organization,
  PageLoader,
  styles,
} from '../components';

const Application = ({ store, history, persistor }) => (
  <Provider store={store}>
    <ThemeProvider theme={styles.theme}>
      <>
        {/* Wrap the layout in a fragment because the theme provider expects a
            single child */}
        <styles.GlobalStyle />
        <PersistGate loading={PageLoader} persistor={persistor}>
          <>
            {/* Wrap the layout in a fragment because the persist gate provider expects a
            single child */}
            <Feedback />
            <ConnectedRouter history={history}>
              <Switch>
                <AuthorizedRoute
                  exact
                  path="/"
                  component={() => <DefaultLayout component={Home} />}
                />
                <Route
                  path="/(signup|login|password-reset-request|password-reset)/"
                  component={Auth}
                />
                <Route
                  path="/(login-callback|signup-callback)/"
                  component={AuthProviderCallback}
                />
                <AuthorizedRoute
                  path="/organizations-dashboard"
                  component={() => (
                    <DefaultLayout component={OrganizationsDashboard} />
                  )}
                />
                <AuthorizedRoute
                  path="/curriculum"
                  component={() => <DefaultLayout component={Curriculum} />}
                />
                <AuthorizedRoute
                  path="/images"
                  component={() => <DefaultLayout component={ImageUpload} />}
                />
                <AuthorizedRoute
                  path="/organization/join/:inviteId"
                  component={() => (
                    <DefaultLayout component={Organization.Join} />
                  )}
                />
                <AuthorizedRoute
                  path="/organization/github-callback"
                  component={() => (
                    <DefaultLayout component={Organization.Callback.Github} />
                  )}
                />
                <AuthorizedRoute
                  path="/organization/slack-callback"
                  component={() => (
                    <DefaultLayout component={Organization.Callback.Slack} />
                  )}
                />
                <Route component={NotFound} />
              </Switch>
            </ConnectedRouter>
          </>
        </PersistGate>
      </>
    </ThemeProvider>
  </Provider>
);

Application.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  persistor: PropTypes.object.isRequired,
};

export default Application;
