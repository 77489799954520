export const name = 'getOrgInvite';

export const cases = {
  creator({ inviteId } = {}) {
    return {
      meta: {
        promise: {
          method: 'GET',
          url: `/organizations/invites/${inviteId}`,
          authenticated: true,
        },
      },
    };
  },
  resolve(state, { payload }) {
    return {
      ...state,
      orgInvite: payload.data,
    };
  },
  reject(state) {
    return {
      ...state,
      orgInvite: null,
    };
  },
};
