export const name = 'getGithubOrgRepos';

export const cases = {
  creator({ login, accessToken, cursor } = {}) {
    return {
      meta: {
        promise: {
          authenticated: false,
          method: 'POST',
          url: `https://api.github.com/graphql`,
          data: {
            // TODO: add pagination
            query: `query OwnerRepositories($login: String!, $cursor: String) {
              repositoryOwner(login: $login) {
                repositories(first: 5, after: $cursor, orderBy: {field: CREATED_AT, direction: DESC}) {
                  totalCount
                  nodes {
                    id
                    nameWithOwner
                    isPrivate
                  }
                  pageInfo {
                    endCursor
                    hasNextPage
                  }
                }
              }
            }`,
            variables: {
              login,
              cursor,
            },
          },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      },
    };
  },
  resolve(state, { payload }) {
    const githubOrganizationData = {
      repositories: [],
      totalRepositories: 0,
      query: {
        accessError: false,
        lastCursor: null,
        isLastPage: false,
      },
    };

    const { data, errors } = payload.data;
    if (!data.repositoryOwner && errors.length) {
      githubOrganizationData.query.accessError = true;
    } else {
      const {
        repositories: {
          totalCount,
          pageInfo: { endCursor, hasNextPage },
          nodes: repositoriesNodes,
        },
      } = data.repositoryOwner;
      githubOrganizationData.repositories = [
        ...state.githubOrganizationData.repositories,
        ...repositoriesNodes,
      ];
      githubOrganizationData.totalRepositories = totalCount;
      githubOrganizationData.query.lastCursor = endCursor;
      githubOrganizationData.query.isLastPage = !hasNextPage;
    }

    return {
      ...state,
      githubOrganizationData,
    };
  },
};
