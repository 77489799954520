export const name = 'searchOrgs';

export const cases = {
  creator({ orgName } = {}) {
    return {
      meta: {
        promise: {
          method: 'GET',
          url: `/organizations/${encodeURIComponent(orgName)}/search`,
          debounce: 300,
          authenticated: true,
        },
      },
    };
  },
  resolve(state, { payload }) {
    const { items: orgs } = payload.data;

    return {
      ...state,
      searchedOrgs: orgs,
    };
  },
  reject(state) {
    return {
      ...state,
      searchedOrgs: [],
    };
  },
};
