export const name = 'fetchOrg';

export const cases = {
  creator({ orgId } = {}) {
    return {
      meta: {
        promise: {
          method: 'GET',
          url: `/organizations/${orgId}`,
          authenticated: true,
        },
      },
    };
  },
  resolve(state, { payload }) {
    const { org } = payload.data;
    const newOrgs = [...state.orgs];
    const orgIndex = newOrgs.findIndex(({ id }) => id === org.id);
    newOrgs[orgIndex] = org;
    return {
      ...state,
      orgs: newOrgs,
      shouldUpdateOrg: false,
    };
  },
};
