import '@babel/polyfill';
import React from 'react';
import { render } from 'react-dom';
import { isNodeDev } from '@teamenki/common-env';
import Application from './views/Application';
import { store, persistor, history } from './redux/store';

const entryNode = document.getElementById('app');

render(
  <Application store={store} history={history} persistor={persistor} />,
  entryNode
);

if (isNodeDev) {
  module.hot.accept();
}
