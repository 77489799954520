export const name = 'feedbackClear';

export const cases = {
  creator({ id } = {}) {
    return { payload: { id } };
  },
  reducer(state, { payload }) {
    return {
      items: state.items.filter(item => item.id !== payload.id),
    };
  },
};
