import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { prop, ifProp } from 'styled-tools';
import { auth as authDuck } from '../../redux/ducks';
import { handleProviderAuth } from '../../utils/auth-provider';
import { Button, Form } from '../styles';
import AuthWrapper from './AuthWrapper';
import ProviderButtons from './ProviderButtons';

const ConsentForm = styled(Form)`
  background: transparent;
  color: ${prop('theme.colors.light')};
  margin: 10px 0;
  padding: 0;

  fieldset {
    display: flex;
    align-items: center;
    justify-content: space-between;

    input {
      width: auto;
      margin: 3px;
    }
  }
`;

const SignupForm = styled(Form)`
  ${ifProp(
    'disabled',
    css`
      opacity: 0.5;
      pointer-events: none;
    `
  )};
`;
class SignUp extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    signup: PropTypes.func.isRequired,
    referrerLocation: PropTypes.shape().isRequired,
    onSuccess: PropTypes.func.isRequired,
  };

  state = {
    hasTermsConsent: false,
    hasAgeConsent: false,
    mail: '',
    username: '',
    password: '',
  };

  handleSignup = e => {
    e.preventDefault();
    return this.props
      .signup({
        mail: this.state.mail,
        username: this.state.username,
        password: this.state.password,
      })
      .then(this.props.onSuccess);
  };

  handleProviderSignupGoogle = () =>
    handleProviderAuth('google-oauth2', 'signup').then(this.props.onSuccess);

  handleProviderSignupGithub = () =>
    handleProviderAuth('github', 'signup').then(this.props.onSuccess);

  handleAgeConsentChange = () =>
    this.setState(prevState => ({
      hasAgeConsent: !prevState.hasAgeConsent,
    }));

  handleTermsConsentChange = () =>
    this.setState(prevState => ({
      hasTermsConsent: !prevState.hasTermsConsent,
    }));

  handlePasswordChange = ({ target: { value } }) =>
    this.setState({ password: value });

  handleMailChange = ({ target: { value } }) =>
    this.setState({
      mail: value,
    });

  handleUsernameChange = ({ target: { value } }) =>
    this.setState({
      username: value,
    });

  hasGivenConsent() {
    return this.state.hasTermsConsent && this.state.hasAgeConsent;
  }

  canSignupWithForm() {
    return (
      this.hasGivenConsent() &&
      this.state.mail &&
      this.state.username &&
      this.state.password
    );
  }

  renderConsentCheckboxes() {
    return (
      <ConsentForm>
        <fieldset>
          <label htmlFor="terms-and-conditions-checkbox">
            <input
              id="terms-and-conditions-checkbox"
              type="checkbox"
              checked={this.state.hasTermsConsent}
              onChange={this.handleTermsConsentChange}
            />
            <span>
              I have read and agree to the{' '}
              <a target="__blank" href="https://enki.com/privacy">
                terms & conditions and privacy & cookies notice
              </a>
            </span>
          </label>
          <label htmlFor="age-consent-checkbox">
            <input
              id="age-consent-checkbox"
              type="checkbox"
              checked={this.state.hasAgeConsent}
              onChange={this.handleAgeConsentChange}
            />
            <span>I hearby acknowledge that I am 16 years of age or older</span>
          </label>
        </fieldset>
      </ConsentForm>
    );
  }

  renderSignupForm() {
    return (
      <SignupForm
        disabled={!this.hasGivenConsent()}
        onSubmit={this.handleSignup}
      >
        <fieldset
          disabled={this.props.isLoading}
          aria-busy={this.props.isLoading}
        >
          <label htmlFor="username">
            Username
            <input
              id="username"
              type="text"
              value={this.state.username}
              onChange={this.handleUsernameChange}
            />
          </label>
          <label htmlFor="mail">
            Email
            <input
              id="mail"
              type="text"
              value={this.state.mail}
              onChange={this.handleMailChange}
            />
          </label>
          <label htmlFor="password">
            Password
            <input
              id="password"
              type="password"
              value={this.state.password}
              onChange={this.handlePasswordChange}
            />
          </label>
          <Button type="submit" disabled={!this.canSignupWithForm()}>
            Sign up
          </Button>
        </fieldset>
      </SignupForm>
    );
  }

  render() {
    return (
      <AuthWrapper>
        {this.renderConsentCheckboxes()}
        <ProviderButtons
          prefixLabel="Sign up"
          canHandleProviderGithub={
            this.hasGivenConsent() && !this.props.isLoading
          }
          canHandleProviderGoogle={
            this.hasGivenConsent() && !this.props.isLoading
          }
          handleProviderGithub={this.handleProviderSignupGithub}
          handleProviderGoogle={this.handleProviderSignupGoogle}
        />
        {this.renderSignupForm()}
        <Link
          to={{
            pathname: '/login',
            state: { from: this.props.referrerLocation },
          }}
        >
          I already have an account
        </Link>
      </AuthWrapper>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  isLoading: auth.meta.signup.isLoading,
});

export default connect(
  mapStateToProps,
  authDuck.actionCreators
)(SignUp);
