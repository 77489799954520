import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import C from '@teamenki/common-constants';
import styled from 'styled-components';
import { prop, ifProp } from 'styled-tools';
import { Button, Form } from './styles';
import Loader from './Loader';

import { content as contentDuck } from '../redux/ducks';

const {
  CONTENT_IMAGES: { MAX_SIZE, MIME_TYPES, FILE_QUALIFIER },
} = C;

const ImageDropzone = styled(Dropzone)`
  align-items: center;
  border: 3px dashed ${prop('theme.colors.dark')};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 20px;
  width: 100%;
  &:hover {
    border-color: ${prop('theme.colors.brand')};
    p {
      color: ${prop('theme.colors.brand')};
    }
  }
`;

const ImageUploadWrapper = styled.div`
  transition: height 0.5s ease-in;
  height: ${ifProp('image', '70%', '100%')};
`;

const ImageUploadForm = styled(Form)`
  padding: 0;
`;

const PreviewImage = styled.img.attrs({
  alt: 'preview',
  draggable: false,
})`
  max-height: 200px;
  max-width: 200px;
  border: 1px solid ${prop('theme.colors.dark')};
`;

class ImageUpload extends Component {
  static propTypes = {
    images: PropTypes.arrayOf(PropTypes.object).isRequired,
    isLoadingImages: PropTypes.bool.isRequired,
    isUploading: PropTypes.bool.isRequired,
    uploadImage: PropTypes.func.isRequired,
    getImages: PropTypes.func.isRequired,
  };

  state = {
    image: null,
    imageName: '',
    uploadProgress: 0,
  };

  componentDidMount() {
    this.props.getImages();
  }

  handleUpload = e => {
    e.preventDefault();
    const { uploadImage } = this.props;
    const { image, imageName } = this.state;
    const data = new FormData();
    data.append(FILE_QUALIFIER, image, imageName);
    uploadImage({
      data,
      handleUploadProgress: this.handleUploadProgress,
    });
  };

  onImageDrop = (acceptedImages = [], rejectedImages = []) => {
    if (rejectedImages.length) {
      console.log('Error, rejected some files', rejectedImages);
    }
    if (acceptedImages.length) {
      this.setState({
        image: acceptedImages[0],
        imageName: '',
      });
    }
  };

  onImageNameChange = e => {
    this.setState({
      imageName: e.target.value,
    });
  };

  handleUploadProgress = progressEvent => {
    const { total, loaded } = progressEvent;
    this.setState(
      {
        uploadProgress: loaded / total,
      },
      () => {
        const { uploadProgress } = this.state;
        if (uploadProgress >= 1) {
          this.setState({
            image: null,
            imageName: '',
            uploadProgress: 0,
          });
        }
      }
    );
  };

  renderDropzone = () => (
    <ImageDropzone
      multiple={false}
      disabled={this.props.isUploading}
      maxSize={MAX_SIZE}
      accept={MIME_TYPES}
      onDrop={this.onImageDrop}
    >
      <p>
        Drag and drop your image or, alternatively, click here to upload your
        image.
      </p>
      <p> Files accepted: jpeg, jpg, png, gif, mp4, mpeg, webm </p>
      <p> Max size: 20 MB</p>
      {this.state.image && <PreviewImage src={this.state.image.preview} />}
    </ImageDropzone>
  );

  renderUploadForm = () => (
    <ImageUploadForm onSubmit={this.handleUpload}>
      {this.props.isUploading &&
        this.state.uploadProgress > 0 && (
          <div style={{ fontSize: '1.5rem', textAlign: 'center' }}>
            {(this.state.uploadProgress * 100).toFixed(2)}%
          </div>
        )}
      <fieldset
        disabled={this.props.isUploading}
        aria-busy={this.props.isUploading}
      >
        <label htmlFor="image-name">
          <input
            id="image-name"
            type="text"
            placeholder="What should be the name of the image?"
            value={this.state.imageName}
            onChange={this.onImageNameChange}
          />
        </label>
        <Button type="submit" disabled={!this.state.imageName}>
          Upload
        </Button>
      </fieldset>
    </ImageUploadForm>
  );

  render() {
    return (
      <ImageUploadWrapper image={!!this.state.image}>
        <div>{this.renderDropzone()}</div>
        {this.state.image && this.renderUploadForm()}
        {this.props.isLoadingImages ? (
          <Loader>Loading your images</Loader>
        ) : (
          <div>
            <h3>Your images</h3>
            <ul>
              {this.props.images.length === 0 ? (
                <p>Once you upload any images, they will appear here</p>
              ) : (
                this.props.images.map(({ url, name }) => (
                  <li key={url}>
                    <a href={url} target="_blank" rel="noopener noreferrer">
                      {name}
                    </a>
                  </li>
                ))
              )}
            </ul>
          </div>
        )}
      </ImageUploadWrapper>
    );
  }
}

const mapStateToProps = ({ content }) => ({
  images: content.images,
  isLoadingImages: content.meta.getImages.isLoading,
  isUploading: content.meta.uploadImage.isLoading,
});

export default connect(
  mapStateToProps,
  contentDuck.actionCreators
)(ImageUpload);
