import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button } from '../../styles';
import LinkPrivateContent from './Link';

import {
  organization as organizationDuck,
  privateContent as privateContentDuck,
} from '../../../redux/ducks';

import {
  getPrivateRepositoryLink,
  getGitHubAppAuthUrl,
} from '../../../utils/github';

class ManagePrivateContent extends Component {
  static propTypes = {
    organization: PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      repo: PropTypes.shape({
        owner: PropTypes.shape({
          name: PropTypes.string.isRequired,
        }).isRequired,
        name: PropTypes.string.isRequired,
        secret: PropTypes.string.isRequired,
      }),
    }).isRequired,
    removePrivateContentRepo: PropTypes.func.isRequired,
    githubAccessToken: PropTypes.string.isRequired,
  };

  handleRemovePrivateRepository = e => {
    e.preventDefault();
    this.props.removePrivateContentRepo({ orgId: this.props.organization.id });
  };

  handleAuthenticateGitHub = e => {
    e.preventDefault();
    window.location = getGitHubAppAuthUrl();
  };

  renderCurrentRepository() {
    return (
      <div>
        <p>This repository is found at </p>
        <a
          href={getPrivateRepositoryLink(this.props.organization.repo)}
          target="_blank"
          rel="noopener noreferrer"
        >{`${this.props.organization.repo.owner.name}/${
          this.props.organization.repo.name
        }`}</a>
        <Button onClick={this.handleRemovePrivateRepository}>
          Archive repository
        </Button>
        <p>
          Note that pressing removing the repository will archive all your
          exiting content.
        </p>
      </div>
    );
  }

  renderNoRepository() {
    return (
      <div>
        {this.props.githubAccessToken ? (
          <LinkPrivateContent organizationId={this.props.organization.id} />
        ) : (
          <div>
            <p>
              This organization has no private content repository set up yet.
              Click the button bellow to authenticate your github account and
              begin the creation process.
            </p>
            <Button onClick={this.handleAuthenticateGitHub}>
              Authenticate github
            </Button>
          </div>
        )}
      </div>
    );
  }

  render() {
    return (
      <div>
        <h5> Private content repository</h5>
        {this.props.organization.repo
          ? this.renderCurrentRepository()
          : this.renderNoRepository()}
      </div>
    );
  }
}

const mapStateToProps = ({ privateContent }) => ({
  githubAccessToken: privateContent.githubAccessToken,
});

export default connect(
  mapStateToProps,
  {
    ...organizationDuck.actionCreators,
    ...privateContentDuck.actionCreators,
  }
)(ManagePrivateContent);
