import styled, { css } from 'styled-components';
import { prop, ifProp } from 'styled-tools';

export default styled.button.attrs({
  type: 'button',
})`
  background: ${prop('theme.colors.brand')};
  border: 0;
  color: white;
  font-size: 1.3rem;
  font-weight: 600;
  padding: 0.65rem 1.3rem;
  cursor: pointer;

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }

  ${ifProp(
    'small',
    css`
      font-size: 1rem;
      padding: 0.5rem 1rem;
    `
  )};
`;
