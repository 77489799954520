import { combineReducers } from 'redux';
import { reducer as offlineQueue } from 'redux-queue-offline';
import optimist from 'redux-optimist';
import { isNodeProd } from '@teamenki/common-env';
import * as ducks from './ducks';

if (!isNodeProd) {
  console.log('Built the following actions:');
  console.log(
    Object.values(ducks).reduce((arr, duck) => arr.concat(duck.actionNames), [])
  );
}

// build a hash of { [duckName]: reducer }
const reducers = Object.keys(ducks).reduce((r, duckName) => {
  // eslint-disable-next-line import/namespace, no-param-reassign
  r[duckName] = ducks[duckName].reducer;
  return r;
}, {});

export default optimist(
  combineReducers({
    offlineQueue,
    ...reducers,
  })
);
