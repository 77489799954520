export const name = 'linkGithubRepoToOrg';

export const cases = {
  creator({ organizationId, nameWithOwner = '', secret } = {}) {
    const [ownerName, repoName] = nameWithOwner.split('/');
    return {
      meta: {
        promise: {
          method: 'POST',
          url: `/organizations/${encodeURIComponent(organizationId)}/repo/`,
          data: {
            repoName,
            ownerName,
            secret,
          },
          authenticated: true,
        },
        feedbackOnSuccess:
          'Successfully linked your github repository to this organization.',
        feedbackOnError: 'Error linking this repository.',
      },
    };
  },
};
