export const name = 'unlinkSlackTeamFromOrganization';

export const cases = {
  creator({ teamId, organizationId } = {}) {
    return {
      meta: {
        promise: {
          method: 'DELETE',
          url: `/organizations/${encodeURIComponent(
            organizationId
          )}/slack-teams/${teamId}`,
          authenticated: true,
        },
        feedbackOnSuccess: `Successfully removed this slack workspace from your organization.`,
        feedbackOnError: `Error removing this member from your workspace`,
      },
    };
  },
  resolve(state) {
    return {
      ...state,
      shouldUpdateOrg: true,
    };
  },
};
