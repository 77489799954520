import C from '@teamenki/common-constants';

const { USER } = C;

export const name = 'fetchMe';

export const cases = {
  creator() {
    return {
      meta: {
        promise: {
          method: 'GET',
          url: '/me',
          authenticated: true,
        },
      },
    };
  },
  reducer(state) {
    return {
      ...state,
      status: USER.STATUS.UNKNOWN,
    };
  },
  resolve(state, { payload }) {
    return {
      ...state,
      status: USER.STATUS.LOGGEDIN,
      me: payload.data,
    };
  },
  reject(state) {
    return {
      ...state,
      status: USER.STATUS.UNAUTHORIZED,
      me: null,
    };
  },
};
