import C from '@teamenki/common-constants';
import * as actions from './actions';
import createDuck from '../create-duck';

const initialState = {
  status: C.USER.STATUS.UNKNOWN,
  me: null,
};

const duck = createDuck('auth', initialState, Object.values(actions));

export const { reducer, actionCreators, actionNames } = duck;
