export const name = 'updateLastAccessedOrg';

export const cases = {
  creator({ id } = {}) {
    return {
      payload: { id },
    };
  },
  reducer(
    state,
    {
      payload: { id },
    }
  ) {
    return {
      ...state,
      lastAccessedOrgId: id,
    };
  },
};
