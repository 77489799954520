import axios from 'axios';
import appConfig from '@teamenki/common-config';

const { GITHUB_PRIVATE_CONTENT_APP_CLIENT_ID } = process.env;

export function getPrivateRepositoryLink({
  name: repoName,
  owner: { name: ownerName },
}) {
  return `https://github.com/${ownerName}/${repoName}/`;
}

export function getGitHubAppAuthUrl() {
  const redirectUri = `${window.location.origin}/organization/github-callback`;
  return `https://github.com/login/oauth/authorize?response_type=code&redirect_uri=${redirectUri}&scope=read:org,repo&client_id=${GITHUB_PRIVATE_CONTENT_APP_CLIENT_ID}`;
}

export function addEnkiCollaboratorToRepository({
  nameWithOwner,
  accessToken,
}) {
  return axios({
    method: 'PUT',
    url: `https://api.github.com/repos/${nameWithOwner}/collaborators/enki-hq`,
    data: {
      permission: 'push',
    },
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

export function addWebhookToRepository({ nameWithOwner, accessToken, secret }) {
  const endpointUrl = `https://api.github.com/repos/${nameWithOwner}/hooks`;
  const webhookConfig = {
    active: true,
    events: ['push', 'pull_request'],
    config: {
      url: `${appConfig.host}/webhook/content`,
      content_type: 'json',
      secret,
    },
  };
  return axios({
    method: 'GET',
    url: endpointUrl,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }).then((res = {}) => {
    const existingWebhooks = res.data || [];
    const existingWebhookIndex = existingWebhooks.findIndex(
      ({ config }) => config.url === webhookConfig.config.url
    );

    if (existingWebhookIndex >= 0) {
      // update
      return axios({
        method: 'PATCH',
        url: `${endpointUrl}/${existingWebhooks[existingWebhookIndex].id}`,
        data: webhookConfig,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    }
    // create
    return axios({
      method: 'POST',
      url: endpointUrl,
      data: {
        name: 'web',
        ...webhookConfig,
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  });
}
