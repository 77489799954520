import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { auth as authDuck } from '../../redux/ducks';
import { handleProviderAuth } from '../../utils/auth-provider';
import { Button, Form } from '../styles';
import ProviderButtons from './ProviderButtons';
import AuthWrapper from './AuthWrapper';

class Login extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    login: PropTypes.func.isRequired,
    referrerLocation: PropTypes.shape().isRequired,
    onSuccess: PropTypes.func.isRequired,
  };

  state = {
    mailOrUsername: '',
    password: '',
  };

  handleMailOrUsernameChange = ({ target: { value } }) =>
    this.setState({
      mailOrUsername: value,
    });

  handlePasswordChange = ({ target: { value } }) =>
    this.setState({
      password: value,
    });

  handleProviderLoginGithub = () =>
    handleProviderAuth('github', 'login').then(this.props.onSuccess);

  handleProviderLoginGoogle = () =>
    handleProviderAuth('google-oauth2', 'login').then(this.props.onSuccess);

  handleLogin = e => {
    e.preventDefault();
    return this.props
      .login({
        mailOrUsername: this.state.mailOrUsername,
        password: this.state.password,
      })
      .then(this.props.onSuccess);
  };

  renderLoginForm() {
    return (
      <Form onSubmit={this.handleLogin}>
        <fieldset
          disabled={this.props.isLoading}
          aria-busy={this.props.isLoading}
        >
          <label htmlFor="mail-username">
            Username or Email
            <input
              id="mail-username"
              type="text"
              value={this.state.mailOrUsername}
              onChange={this.handleMailOrUsernameChange}
            />
          </label>
          <label htmlFor="password">
            Password
            <input
              id="password"
              type="password"
              value={this.state.password}
              onChange={this.handlePasswordChange}
            />
          </label>
          <Button
            type="submit"
            disabled={!this.state.password || !this.state.mailOrUsername}
          >
            Log in
          </Button>
        </fieldset>
      </Form>
    );
  }

  render() {
    return (
      <AuthWrapper>
        <ProviderButtons
          prefixLabel="Log in"
          canHandleProviderGithub={!this.props.isLoading}
          canHandleProviderGoogle={!this.props.isLoading}
          handleProviderGithub={this.handleProviderLoginGithub}
          handleProviderGoogle={this.handleProviderLoginGoogle}
        />
        {this.renderLoginForm()}
        <Link disabled={this.props.isLoading} to="/password-reset-request">
          Forgot password
        </Link>
        <Link
          disabled={this.props.isLoading}
          to={{
            pathname: '/signup',
            state: { from: this.props.referrerLocation },
          }}
        >
          I don&apos;t have an account
        </Link>
      </AuthWrapper>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  isLoading: auth.meta.login.isLoading,
});

export default connect(
  mapStateToProps,
  authDuck.actionCreators
)(Login);
