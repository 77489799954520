import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import C from '@teamenki/common-constants';
import { withRouter } from 'react-router-dom';
import { auth as authDuck } from '../redux/ducks';
import { parseProviderAuth } from '../utils/auth-provider';

class AuthProviderCallback extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    actionCase: PropTypes.string.isRequired,
    loginWithProvider: PropTypes.func.isRequired,
    signupWithProvider: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const {
      history,
      isLoggedIn,
      actionCase,
      loginWithProvider,
      signupWithProvider,
    } = this.props;

    const action =
      actionCase === 'login' ? loginWithProvider : signupWithProvider;

    if (isLoggedIn) {
      history.push('/');
    } else {
      parseProviderAuth(parsedAuth => {
        if (parsedAuth && parsedAuth.idToken) {
          action({
            token: parsedAuth.idToken,
          });
        } else {
          history.push('/login');
        }
      });
    }
  }

  render() {
    return <p>Loading </p>;
  }
}

function mapStateToProps({ auth, router }) {
  return {
    isLoggedIn: auth.status === C.USER.STATUS.LOGGEDIN,
    actionCase: router.location.pathname.includes('login') ? 'login' : 'signup',
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    authDuck.actionCreators
  )(AuthProviderCallback)
);
