import Auth0 from 'auth0-js';

const typeRedirectionMap = {
  login: `${window.location.origin}/login-callback`,
  signup: `${window.location.origin}/signup-callback`,
};

const auth0 = new Auth0.WebAuth({
  domain: 'enkidevs.auth0.com',
  clientID: 'PLQAyakzYBfnPhGdFdpCpMBRV3PmPve3',
  responseType: 'token id_token',
});

export const handleProviderAuth = (connection, type) => {
  if (!typeRedirectionMap[type]) {
    throw new Error('Invalid authentication type');
  }
  return Promise.resolve(
    auth0.authorize({
      connection,
      redirectUri: typeRedirectionMap[type],
    })
  );
};

export const parseProviderAuth = callback =>
  auth0.parseHash((err, result) => {
    callback(result);
  });
