import React from 'react';
import styled from 'styled-components';
import Loader from 'react-loaders';
import Center from './styles/Center';

const PageLoaderWrapper = styled(Center)`
  height: 100vh;
  position: fixed;
  width: 100vw;
`;
export default () => (
  <PageLoaderWrapper>
    <Loader type="line-scale-pulse-out" />
  </PageLoaderWrapper>
);
