import { createGlobalStyle } from 'styled-components';
import { prop } from 'styled-tools';
import nprogress from './nprogress';

export default createGlobalStyle`
  html {
    box-sizing: border-box;
    font-size: 12px;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  body {
    background-color: ${prop('theme.colors.dark')};
    font-family: ${prop('theme.typography.monospace')};
    font-size: 1.3rem;
    line-height: 2;
    margin: 0;
    padding: 0;
  }
  a {
    color: ${prop('theme.colors.dark')};
  }
  button {
    font-family: ${prop('theme.typography.monospace')};
    font-size: 1.3rem;
  }
  input,
  textarea,
  select {
    border-radius: 3px;
    border: 1px solid ${prop('theme.colors.dark')};
    font-family: ${prop('theme.typography.monospace')};
    font-size: 1.3rem;
    padding: 0.5rem;
    width: auto;
    &:focus {
      outline: 0;
      border-color: ${prop('theme.colors.brand')};
    }
    &[disabled] {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
  ${nprogress}
`;
