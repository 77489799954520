import React from 'react';
import PropTypes from 'prop-types';

const RadioButton = ({ label, value, checked, onChange }) => (
  <label htmlFor={`radio-button-${label}`}>
    <input
      type="radio"
      id={`radio-button-${label}`}
      value={value}
      checked={checked}
      onChange={onChange}
    />
    {label}
  </label>
);

RadioButton.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RadioButton;
