import Duck from 'reduck';
import get from 'lodash.get';
import snakeCase from 'lodash.snakecase';

const actionMetaState = {
  isLoading: false,
  error: null,
};

const asyncDefaultState = {
  meta: {},
};

// async meta state is set on the global meta state
// at the action name key
// each time a portion of meta state is set, rest of
// the meta state is reset
function setAsyncState(actionName, state, updates = {}) {
  return {
    ...state,
    ...asyncDefaultState,
    meta: {
      ...asyncDefaultState.meta,
      ...state.meta,
      [actionName]: {
        ...actionMetaState,
        ...asyncDefaultState.meta[actionName],
        ...updates,
      },
    },
  };
}

// every reducer async case function sets the isLoading meta flag
// and resets the rest of the meta state
function setReducerState(actionName, state) {
  return setAsyncState(actionName, state, { isLoading: true });
}

// every resolve async case function resets the meta state
function setResolveState(actionName, state) {
  return setAsyncState(actionName, state);
}

// every reject async case function sets the error meta prop
// and resets the rest of the meta state
function setRejectState(actionName, state, reduxAction) {
  return setAsyncState(actionName, state, {
    error: get(reduxAction, 'payload.response'),
  });
}

function createAsyncCaseFunction(caseFnName, setState) {
  return function pipeActionThroughCaseFunctionIfExists(action) {
    if (typeof action.cases[caseFnName] === 'function') {
      return function asyncCase(state, reduxAction) {
        return action.cases[caseFnName](
          setState(action.name, state, reduxAction),
          reduxAction
        );
      };
    }
    return function asyncCase(state, reduxAction) {
      return setState(action.name, state, reduxAction);
    };
  };
}

const asyncReducer = createAsyncCaseFunction('reducer', setReducerState);
const asyncResolve = createAsyncCaseFunction('resolve', setResolveState);
const asyncReject = createAsyncCaseFunction('reject', setRejectState);

function decorateActionWithAsyncCases(action) {
  return {
    ...action,
    cases: {
      ...action.cases,
      reducer: asyncReducer(action),
      resolve: asyncResolve(action),
      reject: asyncReject(action),
    },
  };
}

const isAsyncAction = action =>
  typeof get(action.cases.creator(), 'meta.promise') !== 'undefined';

const actionName = (type, name) => `${type}.${snakeCase(name).toUpperCase()}`;

const duckActionsToActionCreators = (duck, duckActions) =>
  duckActions.reduce(
    (hash, { name, duckActionName, cases }) =>
      Object.assign(hash, {
        [name]: duck.defineAction(duckActionName, cases),
      }),
    {}
  );

export default function createDuck(type, initialState, actions) {
  if (typeof initialState.meta !== 'undefined') {
    throw Error(
      `Error in "${type}" duck. Keyword \`meta\` is reserved for async duck states`
    );
  }

  const asyncActions = [];
  const localActions = [];

  actions
    .map(action => {
      const async = isAsyncAction(action);
      const decoratedAction = {
        ...action,
        async,
        duckActionName: actionName(type, action.name),
      };
      return async
        ? decorateActionWithAsyncCases(decoratedAction)
        : decoratedAction;
    })
    .forEach(({ async, ...action }) => {
      if (typeof action.name !== 'string') {
        throw Error(`Action must have a "name": ${JSON.stringify(action)}`);
      }
      if (async) {
        asyncActions.push(action);
      } else {
        localActions.push(action);
      }
    });

  const duckInitialState = {
    ...initialState,
    ...asyncDefaultState,
    meta: {
      ...asyncDefaultState.meta,
      ...asyncActions.reduce(
        (hash, { name }) =>
          Object.assign(hash, {
            [name]: actionMetaState,
          }),
        {}
      ),
    },
  };

  const duck = new Duck(type, duckInitialState);

  const duckActions = localActions.concat(asyncActions);

  duck.actionCreators = duckActionsToActionCreators(duck, duckActions);

  duck.actionNames = duckActions.reduce(
    (hash, { name, duckActionName }) =>
      Object.assign(hash, {
        [name]: duckActionName,
      }),
    {}
  );

  return duck;
}
