import PropTypes from 'prop-types';
import React, { Component } from 'react';
import uuid from 'uuid/v4';
import get from 'lodash.get';
import { connect } from 'react-redux';

import { Button } from '../../styles';
import Loader from '../../Loader';
import { RadioButtonGroup } from '../../RadioButton';
import { privateContent as privateContentDuck } from '../../../redux/ducks';
import {
  addEnkiCollaboratorToRepository,
  addWebhookToRepository,
} from '../../../utils/github';

class LinkPrivateContent extends Component {
  static propTypes = {
    githubUser: PropTypes.shape({
      username: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }),
    githubOrganizations: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        id: PropTypes.string,
      })
    ),
    githubAccessToken: PropTypes.string.isRequired,
    revokeGithubAccessToken: PropTypes.func.isRequired,
    getGithubUserOrgs: PropTypes.func.isRequired,
    getGithubOrgRepos: PropTypes.func.isRequired,
    isLoadingUserOrganizations: PropTypes.bool.isRequired,
    githubOrganizationAccessError: PropTypes.bool.isRequired,
    githubOrganizationLastCursor: PropTypes.string,
    githubOrganizationTotalRepositories: PropTypes.number,
    githubOrganizationHasMoreRepositories: PropTypes.bool,
    githubOrganizationRepositories: PropTypes.arrayOf(
      PropTypes.shape({
        nameWithOwner: PropTypes.string.isRequired,
        isPrivate: PropTypes.bool.isRequired,
      })
    ).isRequired,
    linkGithubRepoToOrg: PropTypes.func.isRequired,
    resetGithubOrgLocalData: PropTypes.func.isRequired,
    organizationId: PropTypes.string.isRequired,
    isLoadingGithubData: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    githubUser: {
      username: '',
    },
    githubOrganizations: [],
    githubOrganizationLastCursor: undefined,
    githubOrganizationHasMoreRepositories: true,
    githubOrganizationTotalRepositories: 0,
  };

  state = {
    isLinkingRepository: false,
    linkingError: false,
    currentOrgLogin: null,
  };

  async componentDidMount() {
    if (this.props.githubAccessToken && !this.props.isLoadingGithubData) {
      const { data } = await this.props.getGithubUserOrgs({
        accessToken: this.props.githubAccessToken,
      });
      this.props.resetGithubOrgLocalData();
      const userName = get(data, 'data.viewer.login');
      if (userName) {
        this.props.getGithubOrgRepos({
          login: userName,
          accessToken: this.props.githubAccessToken,
        });
      }
    }
  }

  handleLoadMoreRepositories = e => {
    e.preventDefault();
    this.props.getGithubOrgRepos({
      login: this.state.currentOrgLogin || this.props.githubUser.username,
      accessToken: this.props.githubAccessToken,
      cursor: this.props.githubOrganizationLastCursor,
    });
  };

  handleLinkRepository = nameWithOwner => {
    const secret = uuid();
    this.setState(
      {
        isLinkingRepository: true,
        linkingError: false,
      },
      () =>
        addEnkiCollaboratorToRepository({
          nameWithOwner,
          accessToken: this.props.githubAccessToken,
        })
          .then(() =>
            addWebhookToRepository({
              nameWithOwner,
              accessToken: this.props.githubAccessToken,
              secret,
            }).then(() =>
              this.props.linkGithubRepoToOrg({
                secret,
                nameWithOwner,
                organizationId: this.props.organizationId,
              })
            )
          )
          .catch(() => {
            this.setState({
              isLinkingRepository: false,
              linkingError: true,
            });
          })
    );
  };

  handleRevokeToken = e => {
    e.preventDefault();
    this.props.revokeGithubAccessToken();
    this.props.resetGithubOrgLocalData();
  };

  handleSelectOrganizationOwner = value => {
    this.setState(
      {
        currentOrgLogin: value,
      },
      () => {
        this.props.resetGithubOrgLocalData();
        this.props.getGithubOrgRepos({
          login: this.state.currentOrgLogin,
          accessToken: this.props.githubAccessToken,
          cursor: undefined,
        });
      }
    );
  };

  // TODO: yeah.. this is not readable at all
  generateOrganizationsItemsArray = () => [
    ...(this.props.githubUser
      ? [
          {
            label: this.props.githubUser.username,
            value: this.props.githubUser.username,
          },
        ]
      : []),
    ...(this.props.githubOrganizations.length
      ? this.props.githubOrganizations.map(({ name }) => ({
          label: name,
          value: name,
        }))
      : []),
  ];

  renderOrganizationRepositories() {
    if (this.props.githubOrganizationAccessError) {
      return (
        <p>
          This GitHub organization hes enabled OAuth App access restrictions,
          meaning that data access to third-parties is limited. For more
          information on these restrictions, including how to whitelist this
          app, click{' '}
          <a href="https://help.github.com/articles/restricting-access-to-your-organization-s-data/">
            here
          </a>
          .
        </p>
      );
    }
    return (
      <div>
        <p> Select the repository you want to link with this organization.</p>
        <p>
          We recommend that you chose a freshly created private (empty)
          repository.
        </p>
        <p>
          There are {this.props.githubOrganizationTotalRepositories}{' '}
          repositories in total
        </p>
        <ul>
          {this.props.githubOrganizationRepositories.map(
            ({ isPrivate, nameWithOwner }) => (
              <li key={`org-repo-item-${nameWithOwner}`}>
                <p>
                  {nameWithOwner} - {isPrivate ? 'private' : 'public'}
                </p>
                <Button
                  onClick={() => this.handleLinkRepository(nameWithOwner)}
                >
                  Link
                </Button>
              </li>
            )
          )}
        </ul>
        {this.props.githubOrganizationHasMoreRepositories && (
          <Button onClick={this.handleLoadMoreRepositories}>Load more</Button>
        )}
      </div>
    );
  }

  render() {
    if (this.state.isLinkingRepository) {
      return <p> Linking your repository..please hold on.</p>;
    }
    return (
      <div>
        {this.props.isLoadingUserOrganizations ? (
          <Loader>Loading your organizations</Loader>
        ) : (
          <div>
            {this.state.linkingError && (
              <p>
                There was an ERROR when linking your repository. Please try
                again or get in contact with us at contact@enki.com
              </p>
            )}
            <p>Pleases select the owner of the repository you wish to link</p>
            <RadioButtonGroup
              items={this.generateOrganizationsItemsArray()}
              onChange={this.handleSelectOrganizationOwner}
            />
            {this.renderOrganizationRepositories()}
          </div>
        )}
        <p>
          If you wish to link a repository through another account, press the
          button bellow
        </p>
        <Button onClick={this.handleRevokeToken}>
          Revoke GitHub access token
        </Button>
      </div>
    );
  }
}

const mapStateToProps = ({ privateContent }) => ({
  githubAccessToken: privateContent.githubAccessToken,
  githubUser: privateContent.githubUser,
  githubOrganizations: privateContent.githubOrganizations,
  isLoadingUserOrganizations: privateContent.meta.getGithubUserOrgs.isLoading,
  githubOrganizationRepositories:
    privateContent.githubOrganizationData.repositories,
  githubOrganizationAccessError:
    privateContent.githubOrganizationData.query.accessError,
  githubOrganizationLastCursor:
    privateContent.githubOrganizationData.query.lastCursor,
  githubOrganizationHasMoreRepositories: !privateContent.githubOrganizationData
    .query.isLastPage,
  githubOrganizationTotalRepositories:
    privateContent.githubOrganizationData.totalRepositories,
  isLoadingGithubData:
    privateContent.meta.getGithubUserOrgs.isLoading ||
    privateContent.meta.getGithubOrgRepos.isLoading ||
    false,
});

export default connect(
  mapStateToProps,
  privateContentDuck.actionCreators
)(LinkPrivateContent);
