export const name = 'uploadImage';

export const cases = {
  creator({ data, onUploadProgress } = {}) {
    return {
      meta: {
        promise: {
          method: 'POST',
          url: '/content-images',
          data,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          authenticated: true,
          onUploadProgress,
        },
        feedbackOnError: true,
      },
    };
  },
  resolve(state, { payload }) {
    const { image } = payload.data;
    return {
      ...state,
      images: state.images.concat(image),
    };
  },
};
