export const name = 'revokeGithubAccessToken';

export const cases = {
  creator() {
    return {};
  },
  reducer(state) {
    return {
      ...state,
      githubAccessToken: '',
    };
  },
};
