import React from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import PropTypes from 'prop-types';
import Logo from '../Logo';
import { Card } from '../styles';

const StyledAuthWrapper = styled(Card)`
  background-color: ${prop('theme.colors.dark')};
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  width: 100%;
  a {
    color: ${prop('theme.colors.light')};
  }
  @media (min-width: 768px) {
    width: 40%;
  }
`;

const LogoWrapper = styled.div`
  margin: 5px 0;
`;

export default function AuthWrapper({ children }) {
  return (
    <StyledAuthWrapper>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      {children}
    </StyledAuthWrapper>
  );
}

AuthWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
