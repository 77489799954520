export const name = 'removeMemberFromOrg';

export const cases = {
  creator({ orgId, memberId } = {}) {
    return {
      meta: {
        promise: {
          method: 'DELETE',
          url: `/organizations/${encodeURIComponent(
            orgId
          )}/members/${encodeURIComponent(memberId)}`,
          authenticated: true,
        },
      },
    };
  },
  resolve(state) {
    return {
      ...state,
      shouldUpdateOrg: true,
    };
  },
};
