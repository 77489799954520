import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Slack } from 'styled-icons/fa-brands/Slack';
import { Trash as TrashIcon } from 'styled-icons/fa-solid/Trash';
import isEmpty from 'lodash.isempty';

import { Button } from '../styles';
import { organization as organizationDuck } from '../../redux/ducks';

import getSlackAppAuthUrl from '../../utils/slack';

// eslint-disable-next-line
class ManageSlackTeams extends Component {
  static propTypes = {
    organization: PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      slackTeams: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          teamName: PropTypes.string.isRequired,
        })
      ),
    }).isRequired,
    unlinkSlackTeamFromOrganization: PropTypes.func.isRequired,
    shouldInstallBotToSlackTeam: PropTypes.bool.isRequired,
    dismissShouldInstallBotToSlackTeam: PropTypes.func.isRequired,
  };

  handleAuthenticateSlack = e => {
    e.preventDefault();
    window.location = getSlackAppAuthUrl();
  };

  handleRemoveWorkspace = teamId => {
    this.props.unlinkSlackTeamFromOrganization({
      teamId,
      organizationId: this.props.organization.id,
    });
  };

  handleDismissBotInstallAlert = e => {
    e.preventDefault();
    this.props.dismissShouldInstallBotToSlackTeam();
  };

  render() {
    return (
      <div>
        {!isEmpty(this.props.organization.slackTeams) && (
          <>
            <p>Your linked slack teams:</p>
            <SlackTeamsList
              teams={this.props.organization.slackTeams}
              handleRemoveWorkspace={this.handleRemoveWorkspace}
            />
            <hr />
          </>
        )}
        {this.props.shouldInstallBotToSlackTeam && (
          <BotInstallAlert dismissAlert={this.handleDismissBotInstallAlert} />
        )}
        <div>
          <p>You can add more slack workspaces by clicking the button below</p>
        </div>
        <div>
          <Button onClick={this.handleAuthenticateSlack}>
            <Slack size="30" /> Sign in with Slack
          </Button>
        </div>
      </div>
    );
  }
}

const SlackTeamsList = ({ teams, handleRemoveWorkspace }) => (
  <ul>
    {teams.map(({ teamName, id }) => (
      <li key={`slack-teams-item-${teamName}`}>
        <span>{teamName}</span>
        <span>
          <Button onClick={() => handleRemoveWorkspace(id)}>
            <TrashIcon />
          </Button>
        </span>
      </li>
    ))}
  </ul>
);

SlackTeamsList.propTypes = {
  teams: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      teamName: PropTypes.string.isRequired,
    })
  ).isRequired,
  handleRemoveWorkspace: PropTypes.func.isRequired,
};

const BotInstallAlert = ({ dismissAlert }) => (
  <div>
    <div
      style={{
        border: '3px solid #ff0000',
        borderRadius: '10px',
        padding: '10px',
      }}
    >
      <p> Enkibot is not installed in this workspace.</p>
      <p>
        Instead, click{' '}
        <a
          href="https://bot.enki.com/installation"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>{' '}
        to install it!
      </p>
      <Button onClick={dismissAlert}>Dismiss</Button>
    </div>
  </div>
);

BotInstallAlert.propTypes = {
  dismissAlert: PropTypes.func.isRequired,
};

const mapStateToProps = ({ organization }) => ({
  shouldInstallBotToSlackTeam: organization.shouldInstallBotToSlackTeam,
});

export default connect(
  mapStateToProps,
  organizationDuck.actionCreators
)(ManageSlackTeams);
