import * as actions from './actions';
import createDuck from '../create-duck';

export const types = {
  ERROR: actions.feedbackShowError.type,
  SUCCESS: actions.feedbackShowSuccess.type,
};

export const feedbackState = {
  id: null,
  message: '',
  hideDelay: 1000,
  clearDelay: 0,
  tag: 'feedback',
};

export const initialState = {
  items: [],
};

const duck = createDuck('feedback', initialState, Object.values(actions));

export const { reducer, actionCreators, actionNames } = duck;
