export default {
  brand: '#0051F4',
  lightBg: '#F0F0F0',
  darkBg: '#E8E8E8',
  success: '#6AB04C',
  error: '#EB4D4B',
  light: '#FFF',
  paragraphColor: '#575B5A',
  navColor: '#8C8C8C',
  navBg: '#282B33',
  dark: '#000',
};
