import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { organization as organizationDuck } from '../../redux/ducks';
import { Button } from '../styles';
import Loader from '../Loader';

const MIN_SEARCH_LENGTH = 3;

class SearchOrganization extends Component {
  static propTypes = {
    searchOrgs: PropTypes.func.isRequired,
    joinOrg: PropTypes.func.isRequired,
    rootPath: PropTypes.string.isRequired,
    searchResults: PropTypes.array.isRequired,
    isSearching: PropTypes.bool.isRequired,
  };

  state = {
    searchTerm: '',
  };

  handleSearchOrganization = ({ target: { value } }) => {
    this.setState(
      {
        searchTerm: value,
      },
      () => {
        if (this.state.searchTerm.length >= MIN_SEARCH_LENGTH) {
          this.props.searchOrgs({ orgName: this.state.searchTerm });
        }
      }
    );
  };

  handleJoinOrganization = orgId => {
    this.props.joinOrg({ orgId });
  };

  renderSearchResults() {
    if (this.props.isSearching) {
      return <Loader>Searching orgs</Loader>;
    }
    if (this.state.searchTerm.length === 0) {
      return null;
    }
    if (this.state.searchTerm.length < MIN_SEARCH_LENGTH) {
      return (
        <span style={{ padding: '0 5px' }}>
          <em>
            {`Enter ${MIN_SEARCH_LENGTH - this.state.searchTerm.length} more
          characters`}
          </em>
        </span>
      );
    }
    if (this.props.searchResults.length === 0) {
      return <p>Did not find any organization matching your search</p>;
    }
    return (
      <table>
        <thead>
          <th>Name</th>
          <th>Members</th>
          <th />
        </thead>
        <tbody>
          {this.props.searchResults.map(({ id, name, members }) => (
            <tr key={`organization-${id}`}>
              <td>{name}</td>
              <td>{members}</td>
              <td>
                <Button small onClick={() => this.handleJoinOrganization(id)}>
                  Join
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  render() {
    return (
      <div>
        <h3>You can create your own organization </h3>
        <Link to={`${this.props.rootPath}/create`}>
          <Button>Create organization</Button>
        </Link>
        <h3>Alternatively, search to join an existing public organization</h3>
        <input
          type="search"
          placeholder="Search for organizations..."
          style={{ width: '300px' }}
          value={this.state.searchTerm}
          onChange={this.handleSearchOrganization}
        />
        {this.renderSearchResults()}
      </div>
    );
  }
}

const mapStateToProps = ({ organization }) => ({
  searchResults: organization.searchedOrgs,
  isSearching: organization.meta.searchOrgs.isLoading,
});

export default connect(
  mapStateToProps,
  organizationDuck.actionCreators
)(SearchOrganization);
