export const name = 'getGithubUserOrgs';

export const cases = {
  creator({ accessToken } = {}) {
    return {
      meta: {
        promise: {
          authenticated: false,
          method: 'POST',
          url: `https://api.github.com/graphql`,
          data: {
            query: `query UserOrganizations {
              viewer {
                id
                login
                organizations(first: 100) {
                  nodes {
                    id
                    login
                  }
                }
              }
            }`,
          },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      },
    };
  },
  resolve(state, { payload }) {
    const {
      login: githubUserName,
      id: githubUserId,
      organizations: { nodes: organizationNodes },
    } = payload.data.data.viewer;

    return {
      ...state,
      githubUser: {
        id: githubUserId,
        username: githubUserName,
      },
      githubOrganizations: organizationNodes.map(({ login, ...rest }) => ({
        name: login,
        ...rest,
      })),
    };
  },
};
