import React, { Component } from 'react';
import PropTypes from 'prop-types';

import RadioButton from './RadioButton';

class RadioButtonGroup extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.any,
        label: PropTypes.string,
      })
    ).isRequired,
    onChange: PropTypes.func,
    checkedIndex: PropTypes.number,
  };

  static defaultProps = {
    checkedIndex: 0,
    onChange: () => null,
  };

  state = {
    checkedIndex: this.props.checkedIndex,
  };

  handleRadioButtonChange = clickedIndex => {
    this.setState(
      {
        checkedIndex: clickedIndex,
      },
      () => {
        const value = this.props.items[this.state.checkedIndex].value;
        this.props.onChange(value);
      }
    );
  };

  render() {
    return (
      <div>
        {this.props.items.map(({ label, value }, index) => (
          <RadioButton
            key={`radio-button-${label}`}
            label={label}
            value={value}
            checked={index === this.state.checkedIndex}
            onChange={() => this.handleRadioButtonChange(index)}
          />
        ))}
      </div>
    );
  }
}

export default RadioButtonGroup;
