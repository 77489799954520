import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { auth as authDuck } from '../../redux/ducks';
import AuthWrapper from './AuthWrapper';
import { Button, Form } from '../styles';

class RequestResetPassword extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    requestResetPasswordEmail: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
  };

  state = {
    mailOrUsername: '',
  };

  handleMailOrUsernameChange = ({ target: { value } }) =>
    this.setState({
      mailOrUsername: value,
    });

  handleResetPasswordRequest = e => {
    e.preventDefault();
    return this.props
      .requestResetPasswordEmail({
        mailOrUsername: this.state.mailOrUsername,
      })
      .then(this.props.onSuccess);
  };

  renderRequestResetPasswordForm() {
    return (
      <Form onSubmit={this.handleResetPasswordRequest}>
        <fieldset
          disabled={this.props.isLoading}
          aria-busy={this.props.isLoading}
        >
          <label htmlFor="mail-username">
            Username or Email
            <input
              id="mail-username"
              type="text"
              value={this.state.mailOrUsername}
              onChange={this.handleMailOrUsernameChange}
            />
          </label>
          <Button type="submit" disabled={!this.state.mailOrUsername}>
            Reset Password
          </Button>
        </fieldset>
      </Form>
    );
  }

  render() {
    return (
      <AuthWrapper>
        {this.renderRequestResetPasswordForm()}
        <Link to="/login">I remember my password</Link>
      </AuthWrapper>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  isLoading: auth.meta.resetPassword.isLoading,
});

export default connect(
  mapStateToProps,
  authDuck.actionCreators
)(RequestResetPassword);
