import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { organization as organizationDuck } from '../../redux/ducks';
import { Button } from '../styles';

class JoinOrganization extends Component {
  static propTypes = {
    getOrgInvite: PropTypes.func.isRequired,
    acceptOrgInvite: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    orgInvite: PropTypes.shape({
      orgName: PropTypes.string,
      inviter: PropTypes.string,
      isMember: PropTypes.bool,
      accepted: PropTypes.bool,
    }).isRequired,
  };

  componentDidMount() {
    this.props.getOrgInvite({ inviteId: this.props.match.params.inviteId });
  }

  handleAcceptOrgInvite = () => {
    this.props.acceptOrgInvite({ inviteId: this.props.match.params.inviteId });
  };

  render() {
    if (!this.props.orgInvite) {
      if (this.props.isLoading) {
        return <div>Loading</div>;
      }
      return <div>Cannot find invite</div>;
    }

    if (this.props.orgInvite.isMember) {
      return <div>You are already a member of this team</div>;
    }

    if (this.props.orgInvite.accepted) {
      return <div>This invite was already accepted</div>;
    }

    return (
      <div>
        <h3>
          You were invited{' '}
          {this.props.orgInvite.inviter
            ? `by ${this.props.orgInvite.inviter}`
            : ''}{' '}
          to join the <i>{this.props.orgInvite.orgName}</i> organization
        </h3>
        <Button onClick={this.handleAcceptOrgInvite}>Accept</Button>
      </div>
    );
  }
}

function mapStateToProps({ organization }) {
  return {
    isLoading: organization.meta.getOrgInvite.isLoading,
    orgInvite: organization.orgInvite,
  };
}

export default connect(
  mapStateToProps,
  organizationDuck.actionCreators
)(JoinOrganization);
