export const name = 'dismissShouldInstallBotToSlackTeam';

export const cases = {
  creator() {
    return {};
  },
  reducer(state) {
    return {
      ...state,
      shouldInstallBotToSlackTeam: false,
    };
  },
};
