import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled, { css, keyframes } from 'styled-components';
import { prop, ifProp } from 'styled-tools';
import { CheckCircle, TimesCircle } from 'styled-icons/fa-regular';
import { feedback as feedbackDuck } from '../redux/ducks';
import { Alert } from './styles';

const slideIn = keyframes`
  0% {
    opacity: 0;
    transform: translateX(110%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
`;

const slideOut = keyframes`
  0% {
    opacity: 1;
    transform: translateX(0%);
  }
  100% {
    opacity: 0;
    transform: translateX(110%);
  }
`;

const FeedbackAlert = styled(Alert)`
  align-items: center;
  animation-delay: ${prop('delay')};
  animation-duration: ${prop('duration')};
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  border-radius: 5px;
  top: ${props => props.position * 50}px;
  display: flex;
  justify-content: flex-start;
  margin-top: 5px;
  padding: 5px;
  transform: translateX(110%);
  width: 100%;
  ${ifProp(
    'visible',
    css`
      animation-name: ${slideIn};
    `,
    css`
      animation-name: ${slideOut};
    `
  )};
  & > div {
    width: 100%;
    padding-left: 5px;
  }
`;

const FeedbackAlertsWrapper = styled.div`
  height: 100%;
  position: fixed;
  right: 0;
  padding: 10px;
  width: 100%;
  @media (min-width: 768px) {
    width: 25%;
  }

  ${ifProp(
    'empty',
    css`
      display: none;
    `
  )};
`;

const Icon = ({ type }) => {
  switch (type) {
    case feedbackDuck.types.SUCCESS:
      return <CheckCircle size="25" />;
    case feedbackDuck.types.ERROR:
      return <TimesCircle size="25" />;
    default:
      return null;
  }
};

const Feedback = ({ items, feedbackClear }) => (
  <FeedbackAlertsWrapper empty={items.length === 0}>
    {items.map((item, i) => (
      <FeedbackAlert
        position={i}
        delay={`${i * item.clearDelay}ms`}
        duration={`${item.clearDelay}ms`}
        key={item.id}
        visible={item.visible}
        success={item.type === feedbackDuck.types.SUCCESS}
        error={item.type === feedbackDuck.types.ERROR}
        onClick={feedbackClear}
      >
        <Icon type={item.type} />
        <div>{item.message}</div>
      </FeedbackAlert>
    ))}
  </FeedbackAlertsWrapper>
);

Feedback.propTypes = {
  feedbackClear: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      visible: PropTypes.bool.isRequired,
      type: PropTypes.oneOf(Object.values(feedbackDuck.types)).isRequired,
      message: PropTypes.string.isRequired,
      hideDelay: PropTypes.number.isRequired,
      clearDelay: PropTypes.number.isRequired,
      tag: PropTypes.string.isRequired,
    })
  ).isRequired,
};

function mapStateToProps({ feedback }) {
  return {
    items: feedback.items,
  };
}

export default connect(
  mapStateToProps,
  feedbackDuck.actionCreators
)(Feedback);
export const dumb = Feedback;
