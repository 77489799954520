import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import get from 'lodash.get';
import { auth as authDuck } from '../redux/ducks';
import Logo from './Logo';
import { Avatar, Button } from './styles';

const SIDEBAR_WIDTH = '250px';
const HEADER_HEIGHT = '50px';

const Username = styled.p`
  color: ${prop('theme.colors.light')};
  padding: 0.5rem 1rem;
`;

const Sidebar = styled.div`
  a {
    color: ${prop('theme.colors.light')};
  }
  width: ${SIDEBAR_WIDTH};
  height: calc(100vh - ${HEADER_HEIGHT});
`;

const Nav = styled.nav`
  width: 100%;
`;

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 5px;

  & > li {
    padding: 5px 10px;
  }
`;

const Header = styled.header`
  align-items: center;
  display: flex;
  height: ${HEADER_HEIGHT};
  padding: 5px;
`;

const HeaderMenu = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
`;

const HeaderLogo = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: ${SIDEBAR_WIDTH};
`;

const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
`;

const ComponentWrapper = styled.div`
  background-color: ${prop('theme.colors.light')};
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.2);
  height: calc(100vh - ${HEADER_HEIGHT});
  overflow: auto;
  padding: 10px;
  width: calc(100% - ${SIDEBAR_WIDTH});
`;

const DefaultLayout = ({
  component: Component,
  logout,
  avatar,
  username,
  ...rest
}) => (
  <>
    <Header>
      <HeaderLogo>
        <Logo />
      </HeaderLogo>
      <HeaderMenu>
        <Username>{username}</Username>
        <Avatar src={avatar} style={{ marginRight: '10px' }} />
        <Button small onClick={logout}>
          Log out
        </Button>
      </HeaderMenu>
    </Header>
    <ContentWrapper>
      <Sidebar>
        <Nav>
          <List>
            <li>
              <Link to="/images">Content Images Uploader</Link>
            </li>
            <li>
              <Link to="/organizations-dashboard">Organizations Dashboard</Link>
            </li>
            <li>
              <Link to="/curriculum">Curriculum</Link>
            </li>
          </List>
        </Nav>
      </Sidebar>
      <ComponentWrapper>
        <Component {...rest} />
      </ComponentWrapper>
    </ContentWrapper>
  </>
);

DefaultLayout.propTypes = {
  avatar: PropTypes.string,
  component: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

DefaultLayout.defaultProps = {
  avatar: '',
};

function mapStateToProps({ auth }) {
  return {
    avatar: get(auth, 'me.avatar'),
    username: get(auth, 'me.username'),
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    authDuck.actionCreators
  )(DefaultLayout)
);
