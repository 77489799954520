import React from 'react';

export default function Curriculum() {
  return (
    <div>
      <input type="search" placeholder="Search for an insight..." />
      <hr />
      <form>
        <input type="checkbox" /> Bookmarked
        <input type="checkbox" /> Learned
      </form>
      <div>Curriculum goes here</div>
    </div>
  );
}
