import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import C from '@teamenki/common-constants';
import { Route, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import queryString from 'query-string';
import { auth as authDuck } from '../redux/ducks';

import { Auth as AuthComponents, styles } from '../components';

const AuthWrapper = styled(styles.Center)`
  height: 100vh;
  width: 100vw;
`;

class Auth extends Component {
  static propTypes = {
    fetchMe: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      state: PropTypes.shape({
        redirectedBecauseUnauthorized: PropTypes.bool,
        from: PropTypes.shape(),
      }),
    }).isRequired,
  };

  state = {
    redirectToReferrer: false,
  };

  componentDidMount() {
    if (!this.getIsRedirectedBecauseUnauthorized()) {
      this.props.fetchMe();
    }
  }

  getReferrerLocation = () => {
    const { from } = this.props.location.state || { from: { pathname: '/' } };
    return from;
  };

  getIsRedirectedBecauseUnauthorized = () => {
    const { redirectedBecauseUnauthorized = false } =
      this.props.location.state || {};
    return redirectedBecauseUnauthorized;
  };

  handleRedirectToReferrer = () => {
    this.setState({
      redirectToReferrer: true,
    });
  };

  renderAuthComponent = () => {
    const referrerLocation = this.getReferrerLocation();
    return (
      <>
        <Route
          path="/login"
          component={() => (
            <AuthComponents.Login
              referrerLocation={referrerLocation}
              onSuccess={this.handleRedirectToReferrer}
            />
          )}
        />
        <Route
          path="/signup"
          component={() => (
            <AuthComponents.SignUp
              referrerLocation={referrerLocation}
              onSuccess={this.handleRedirectToReferrer}
            />
          )}
        />
        <Route
          path="/password-reset-request"
          component={() => (
            <AuthComponents.RequestResetPassword
              referrerLocation={referrerLocation}
              onSuccess={this.handleRedirectToReferrer}
            />
          )}
        />
        <Route
          path="/password-reset"
          component={() => {
            const { email, token } = queryString.parse(window.location.search);
            return (
              <AuthComponents.ResetPassword
                email={email}
                token={token}
                onSuccess={this.handleRedirectToReferrer}
              />
            );
          }}
        />
      </>
    );
  };

  render() {
    if (this.state.redirectToReferrer) {
      return <Redirect to={this.getReferrerLocation()} />;
    }

    if (!this.getIsRedirectedBecauseUnauthorized() && this.props.isLoggedIn) {
      return <Redirect to="/" />;
    }

    return <AuthWrapper>{this.renderAuthComponent()}</AuthWrapper>;
  }
}

function mapStateToProps({ auth }) {
  return {
    isLoggedIn: auth.status === C.USER.STATUS.LOGGEDIN,
  };
}

export default connect(
  mapStateToProps,
  authDuck.actionCreators
)(Auth);
