import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import { Github, Google } from 'styled-icons/fa-brands';
import { Button } from '../styles';

const ProviderButtonsWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  width: 100%;
`;

const ProviderButton = styled(Button).attrs({ type: 'button' })`
  background-color: ${prop('theme.colors.light')};
  color: ${prop('theme.colors.dark')};
  align-items: center;
  border-radius: 2px;
  display: flex;
  padding: 10px 5px;
  width: 48%;
  > svg {
    float: left;
  }
  > span {
    width: 100%;
  }
`;

export default function ProviderButtons(props) {
  return (
    <ProviderButtonsWrapper>
      <ProviderButton
        disabled={!props.canHandleProviderGithub}
        onClick={props.handleProviderGithub}
      >
        <Github size="30" /> <span>{props.prefixLabel} with GitHub</span>
      </ProviderButton>
      <ProviderButton
        disabled={!props.canHandleProviderGoogle}
        onClick={props.handleProviderGoogle}
      >
        <Google size="30" /> <span>{props.prefixLabel} with Google</span>
      </ProviderButton>
    </ProviderButtonsWrapper>
  );
}

ProviderButtons.propTypes = {
  prefixLabel: PropTypes.string.isRequired,
  canHandleProviderGithub: PropTypes.bool,
  handleProviderGithub: PropTypes.func.isRequired,
  canHandleProviderGoogle: PropTypes.bool,
  handleProviderGoogle: PropTypes.func.isRequired,
};

ProviderButtons.defaultProps = {
  canHandleProviderGithub: true,
  canHandleProviderGoogle: true,
};
