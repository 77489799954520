export const name = 'joinOrg';

export const cases = {
  creator({ orgId } = {}) {
    return {
      meta: {
        promise: {
          method: 'POST',
          url: `/organizations/${encodeURIComponent(orgId)}/join`,
          authenticated: true,
        },
        redirectOnSuccess: { replace: '/organizations-dashboard' },
      },
    };
  },
};
