import * as actions from './actions';
import createDuck from '../create-duck';
import { actionNames as privateContentActionNames } from '../private-content';

const initialState = {
  orgs: [],
  searchedOrgs: [],
  orgInvite: {},
  isOrgNameAvailable: true,
  shouldUpdateOrg: false,
  lastAccessedOrgId: null,
  shouldInstallBotToSlackTeam: false,
};

const duck = createDuck('organization', initialState, Object.values(actions));

duck.addReducerCase(privateContentActionNames.linkGithubRepoToOrg, {
  resolve(state) {
    return {
      ...state,
      shouldUpdateOrg: true,
    };
  },
});

duck.addReducerCase(privateContentActionNames.removePrivateContentRepo, {
  resolve(state) {
    return {
      ...state,
      shouldUpdateOrg: true,
    };
  },
});

export const { reducer, actionCreators, actionNames } = duck;
