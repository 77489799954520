import styled, { keyframes } from 'styled-components';
import { prop } from 'styled-tools';

const loading = keyframes`
  from {
    background-position: 0 0;
  }

  to {
    background-position: 100% 100%;
  }
`;

const Form = styled.form`
  background: ${prop('theme.colors.light')};
  font-size: 1.3rem;
  line-height: 1.5;
  font-weight: 600;
  width: 100%;

  label {
    display: block;
    margin-bottom: 1rem;
  }

  button,
  input {
    width: 100%;
  }

  fieldset {
    border: 0;
    padding: 20px;

    &[disabled] {
      opacity: 0.5;
      cursor: not-allowed;

      &::before {
        background-image: linear-gradient(
          to right,
          ${prop('theme.colors.brand')} 0%,
          ${prop('theme.colors.dark')} 50%,
          ${prop('theme.colors.brand')} 100%
        );
      }
    }

    &::before {
      height: 10px;
      content: '';
      display: block;
    }
    &[aria-busy='true']::before {
      background-size: 50% auto;
      animation: ${loading} 0.5s linear infinite;
    }
  }
`;

export default Form;
