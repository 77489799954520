export const name = 'fetchOrgs';

export const cases = {
  creator() {
    return {
      meta: {
        promise: {
          method: 'GET',
          url: '/organizations',
          authenticated: true,
        },
      },
    };
  },
  resolve(state, { payload }) {
    const { items: orgs } = payload.data;
    return {
      ...state,
      orgs,
      shouldUpdateOrg: false,
    };
  },
  reject(state) {
    return {
      ...state,
      // TODO: figure out if this is what we want. this will reset the existing cache in case of error
      // orgs: [],
      shouldUpdateOrg: false,
    };
  },
};
