export const name = 'getGithubAccessToken';

export const cases = {
  creator({ code, orgId } = {}) {
    return {
      meta: {
        promise: {
          method: 'GET',
          url: `/organizations/access-tokens/github/${code}`,
          authenticated: true,
        },
        redirectOnSuccess: {
          replace: `/organizations-dashboard/organization/${orgId}`,
        },
        redirectOnError: { replace: '/organizations-dashboard' },
        feedbackOnSuccess: `Successfully authenticated your GitHub account.`,
        feedbackOnError: `GitHub authentication failed`,
      },
    };
  },
  resolve(state, { payload }) {
    const { accessToken } = payload.data;
    return {
      ...state,
      githubAccessToken: accessToken,
    };
  },
};
