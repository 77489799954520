import React from 'react';

const style =
  '.enkilogo-1{font-size:18.3px;fill:#fff;font-family:Futura-Bold, Futura;font-weight:700;letter-spacing:0.06em;}.enkilogo-2,.enkilogo-4{opacity:0.5;}.enkilogo-3{font-size:19.25px;letter-spacing:0.06em;}.enkilogo-4{letter-spacing:0.06em;}';

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="94px"
    height="24px"
    viewBox="0 0 93.61 23.5"
  >
    <defs>
      <style>{style}</style>
    </defs>
    <title>Asset 1</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <text className="enkilogo-1" transform="translate(0 16.88)">
          <tspan className="enkilogo-2">&lt;</tspan>
          <tspan className="enkilogo-3" x="13.54" y="0">
            ENKI
          </tspan>
          <tspan className="enkilogo-4" x="68.73" y="0">
            /&gt;
          </tspan>
        </text>
      </g>
    </g>
  </svg>
);
