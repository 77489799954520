export const name = 'inviteMemberToOrg';

export const cases = {
  creator({ orgId, email } = {}) {
    return {
      meta: {
        promise: {
          method: 'POST',
          url: `/organizations/${orgId}/invite`,
          debounce: 300,
          data: {
            emails: [email],
          },
          authenticated: true,
        },
        feedbackOnSuccess: `Successfully invited ${email}`,
      },
    };
  },
};
