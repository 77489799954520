export const name = 'resetGithubOrgLocalData';

export const cases = {
  creator() {
    return {};
  },
  reducer(state) {
    return {
      ...state,
      githubOrganizationData: {
        totalRepositories: 0,
        repositories: [],
        query: {
          accessError: false,
          lastCursor: undefined,
          lastPage: false,
        },
      },
    };
  },
};
