import React from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import { Center } from './styles';

const StyledNotFound = styled(Center)`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${prop('theme.colors.light')};
`;

const NotFound = () => <StyledNotFound>Not Found</StyledNotFound>;

export default NotFound;
