import get from 'lodash.get';

export const name = 'linkSlackTeamToOrg';

export const cases = {
  creator({ code, organizationId, redirectUri } = {}) {
    return {
      meta: {
        promise: {
          method: 'POST',
          url: `/organizations/${encodeURIComponent(
            organizationId
          )}/slack-teams`,
          data: {
            code,
            redirectUri,
          },
          authenticated: true,
        },
        redirectOnSuccess: {
          replace: `/organizations-dashboard/organization/${organizationId}`,
        },
        redirectOnError: {
          replace: `/organizations-dashboard/organization/${organizationId}`,
        },
        feedbackOnSuccess: `Successfully authenticated your Slack account.`,
        feedbackOnError: true,
        feedbackDelay: 3000,
      },
    };
  },
  reducer(state) {
    return {
      ...state,
      shouldInstallBotToSlackTeam: false,
    };
  },
  resolve(state) {
    return {
      ...state,
      shouldUpdateOrg: true,
    };
  },
  reject(state) {
    const errorStatusCode = get(state, 'linkSlackTeamToOrg.meta.error.status');
    return errorStatusCode === 422
      ? {
          ...state,
          shouldInstallBotToSlackTeam: true,
        }
      : state;
  },
};
