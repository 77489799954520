import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import {
  organization as organizationDuck,
  privateContent as privateContentDuck,
} from '../../../redux/ducks';

class OrganizationGitHubCallback extends Component {
  static propTypes = {
    location: PropTypes.shape({
      search: PropTypes.string,
    }).isRequired,
    orgId: PropTypes.string,
    getGithubAccessToken: PropTypes.func.isRequired,
  };

  static defaultProps = {
    orgId: null,
  };

  componentDidMount() {
    const { code } = queryString.parse(this.props.location.search);
    this.props.getGithubAccessToken({ code, orgId: this.props.orgId });
  }

  render() {
    return <h3> Authenticating GitHub </h3>;
  }
}

const mapStateToProps = ({ organization }) => ({
  orgId: organization.lastAccessedOrgId,
});

export default withRouter(
  connect(
    mapStateToProps,
    {
      ...organizationDuck.actionCreators,
      ...privateContentDuck.actionCreators,
    }
  )(OrganizationGitHubCallback)
);
