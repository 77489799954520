import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { prop } from 'styled-tools';

const StyledCard = styled.div`
  border-radius: 2px;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.2);
  background-color: ${prop('theme.colors.light')};
  padding: 20px;
`;

export default function Card(props) {
  return <StyledCard {...props}>{props.children}</StyledCard>;
}

Card.propTypes = {
  children: PropTypes.node.isRequired,
};
