import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Route, Switch } from 'react-router-dom';
import { organization as organizationDuck } from '../redux/ducks';

import { Loader, Organization, styles } from '../components';

class Dashboard extends Component {
  static propTypes = {
    fetchOrgs: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    orgs: PropTypes.arrayOf(PropTypes.object).isRequired,
    isFetchingOrgs: PropTypes.bool.isRequired,
    location: PropTypes.any.isRequired,
  };

  componentDidMount() {
    this.props.fetchOrgs();
  }

  componentDidUpdate(prevProps) {
    const currentPathname = this.props.location.pathname;
    const previousPathname = prevProps.location.pathname;

    if (this.shouldReFetchOrgs(currentPathname, previousPathname)) {
      this.props.fetchOrgs();
    }
  }

  // TODO: to minimise the number of non-necessary re-fetches, instead, when redirecting post-successful action
  // append &new=true to the URl and test for that
  shouldReFetchOrgs = (currentPathname, previousPathname) => {
    const shouldReFetchFrom = [
      '/organizations-dashboard/join',
      '/organizations-dashboard/create',
      /\/organizations-dashboard\/organization\/[a-z0-9]{24}/,
    ];
    return (
      currentPathname === '/organizations-dashboard' &&
      shouldReFetchFrom.some(path => new RegExp(path).test(previousPathname))
    );
  };

  render() {
    const { path } = this.props.match;
    if (this.props.isFetchingOrgs) {
      return <Loader>Loading your organizations, please stand by</Loader>;
    }
    return (
      <div>
        <styles.Button
          as={Link}
          to={`${path}/join`}
          style={{ textDecoration: 'none' }}
        >
          Join or Create an organization
        </styles.Button>
        <h4>Your organizations</h4>
        <ul>
          {this.props.orgs.map(({ name, id }) => (
            <li key={`org-li-${id}`}>
              <Link to={`${path}/organization/${id}`}>{name}</Link>
            </li>
          ))}
        </ul>
        <div>
          <Switch>
            <Route
              path={path}
              exact
              component={() => (
                <p>
                  Choose what organization you want to administrate. You can
                  also create a new one or join existing organizations.
                </p>
              )}
            />
            {this.props.orgs.map(org => (
              <Route
                path={`${path}/organization/${org.id}`}
                component={() => <Organization.View organization={org} />}
                key={`org-route-${org.id}`}
              />
            ))}
            <Route
              path={`${path}/join`}
              component={() => <Organization.Search rootPath={path} />}
            />
            <Route path={`${path}/create`} component={Organization.Create} />
          </Switch>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ organization }) => ({
  orgs: organization.orgs,
  isFetchingOrgs: organization.meta.fetchOrgs.isLoading,
});

export default connect(
  mapStateToProps,
  organizationDuck.actionCreators
)(Dashboard);
