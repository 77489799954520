import uuid from 'uuid/v4';

export const type = 'success';

export const name = 'feedbackShowSuccess';

export const cases = {
  creator({ id = uuid(), message, hideDelay, clearDelay, tag } = {}) {
    return {
      payload: {
        id,
        hideDelay,
        clearDelay,
        message,
        tag,
      },
    };
  },
  reducer(state, { payload }) {
    return {
      ...state,
      items: [
        ...state.items,
        {
          id: uuid(),
          ...payload,
          visible: true,
          type,
        },
      ],
    };
  },
};
