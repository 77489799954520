export const name = 'acceptOrgInvite';

export const cases = {
  creator({ inviteId } = {}) {
    return {
      meta: {
        promise: {
          method: 'POST',
          url: `/organizations/invites/${inviteId}/accept`,
          authenticated: true,
        },
        redirectOnSuccess: { replace: '/organizations-dashboard' },
      },
    };
  },
};
