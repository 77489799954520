import React from 'react';
import ReactLoader from 'react-loaders';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { prop } from 'styled-tools';

const LoaderWrapper = styled.div`
  padding: 10px;
  .loader-inner div {
    background-color: ${prop('theme.colors.brand')};
  }
`;

export default function Loader({ children }) {
  return (
    <LoaderWrapper>
      <ReactLoader type="ball-beat" />
      {children ? (
        <span>
          {children}
          ...
        </span>
      ) : null}
    </LoaderWrapper>
  );
}

Loader.propTypes = {
  children: PropTypes.node.isRequired,
};
