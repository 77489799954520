import { applyMiddleware, createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import {
  connectRouter,
  routerMiddleware as createRouterMiddleware,
  LOCATION_CHANGE,
  CALL_HISTORY_METHOD,
} from 'connected-react-router';
import reduxMetaObjectToAxiosRequest from '@enkidevs/redux-meta-object-to-axios-request';
import optimistPromise from 'redux-optimist-promise';
import { createLogger } from 'redux-logger';
import { createBrowserHistory } from 'history';
import localForage from 'localforage';
import { isDev, isNodeProd } from '@teamenki/common-env';
import config from '@teamenki/common-config';

import rootReducer from './reducers';
import {
  redirector as redirectorMiddleware,
  feedback as feedbackMiddleware,
} from './middlewares';

export const history = createBrowserHistory();

const loggerMiddleware = createLogger({
  collapsed: true,
  duration: true,
  timestamp: false,
});

const routerMiddleware = createRouterMiddleware(history);
const reduxMetaObjectToAxiosRequestMiddleware = reduxMetaObjectToAxiosRequest({
  axiosOptions: { baseURL: `${config.api}/v2`, timeout: isDev ? 60000 : 10000 },
  tokenOptions: { key: 'nk_token', storage: localForage },
});
const optimistPromiseMiddleware = optimistPromise();

const middlewares = [
  routerMiddleware,
  redirectorMiddleware,
  feedbackMiddleware,
  ...(isNodeProd ? [] : [loggerMiddleware]),
  reduxMetaObjectToAxiosRequestMiddleware,
  optimistPromiseMiddleware,
];

const persistConfig = {
  key: 'root',
  storage: localForage,
  stateReconciler: autoMergeLevel2,
  blacklist: [LOCATION_CHANGE, CALL_HISTORY_METHOD, 'auth', 'feedback'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const createStoreWithMiddleware = applyMiddleware(...middlewares)(createStore);
export const store = createStoreWithMiddleware(
  connectRouter(history)(persistedReducer)
);

export const persistor = persistStore(store);
