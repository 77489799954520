import C from '@teamenki/common-constants';

const { USER } = C;

export const name = 'resetPassword';

export const cases = {
  creator({ email, token, password } = {}) {
    return {
      meta: {
        promise: {
          method: 'POST',
          url: '/auth/reset-password-finalize',
          authenticated: false,
          data: {
            email,
            token,
            password,
          },
        },
        redirectOnSuccess: {
          back: true,
        },
      },
    };
  },
  reducer(state) {
    return {
      ...state,
      status: USER.STATUS.UNAUTHORIZED,
      me: null,
    };
  },
};
