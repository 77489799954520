import * as actions from './actions';
import createDuck from '../create-duck';

const initialState = {
  images: [],
};

const duck = createDuck('content', initialState, Object.values(actions));

export const { reducer, actionCreators, actionNames } = duck;
