export const name = 'feedbackHide';

export const cases = {
  creator({ id } = {}) {
    return { payload: { id } };
  },
  reducer(state, { payload }) {
    const index = state.items.findIndex(item => item.id === payload.id);
    return {
      ...state,
      items: state.items.map(
        (item, i) => (i === index ? { ...item, visible: false } : item)
      ),
    };
  },
};
