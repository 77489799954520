import {
  isRejectedAction,
  isResolvedAction,
  isThenAction,
} from 'redux-optimist-promise';
import { push, goBack, replace } from 'connected-react-router';
import { getActionWithOriginCreator } from './helpers';

const origin = getActionWithOriginCreator();

function redirect({ redirection, action, getState, dispatch, pushAction }) {
  if (typeof redirection === 'function') {
    const state = getState();
    const payload = redirection(action, state);
    dispatch(origin(pushAction(payload), action));
  } else if (typeof redirection === 'object') {
    if (redirection.back) {
      dispatch(origin(goBack(), action));
    } else if (redirection.relative) {
      const { routing } = getState();
      dispatch(
        origin(
          pushAction(routing.location.pathname + redirection.relative),
          action
        )
      );
    } else if (redirection.clearHistory && redirection.location) {
      // clearHistory is handled in the ui duck
      redirect({
        redirection: redirection.location,
        action,
        getState,
        dispatch,
        pushAction: replace,
      });
    } else if (redirection.replace) {
      redirect({
        redirection: redirection.replace,
        action,
        getState,
        dispatch,
        pushAction: replace,
      });
    } else if (redirection.external && redirection.location) {
      window.location = redirection.location;
    } else if (redirection.target && redirection.location) {
      window.open(redirection.location, redirection.target);
    } else if (redirection.routeName) {
      dispatch(origin(pushAction(redirection), action));
    }
  } else {
    dispatch(origin(pushAction(redirection), action));
  }
}

export function getRedirectMeta(action) {
  const { meta, type } = action;
  if (
    !isThenAction(type) &&
    meta &&
    meta.redirectDirectly &&
    !meta.skipOptimist
  ) {
    return meta.redirectDirectly;
  }
  if (isRejectedAction(type) && meta && meta.redirectOnError) {
    return meta.redirectOnError;
  }
  if (
    isResolvedAction(type) &&
    meta &&
    typeof meta.redirectOnSuccess === 'function'
  ) {
    return meta.redirectOnSuccess(action);
  }
  if (isResolvedAction(type) && meta && meta.redirectOnSuccess) {
    return meta.redirectOnSuccess;
  }
  return null;
}

export default ({ dispatch, getState }) => next => action => {
  const result = next(action);

  const redirectMeta = getRedirectMeta(action);
  if (redirectMeta) {
    redirect({
      redirection: redirectMeta,
      action,
      getState,
      dispatch,
      pushAction: push,
    });
  }

  return result;
};
