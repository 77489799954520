export const name = 'createOrg';

export const cases = {
  creator({ name: orgName, purpose, isPublic } = {}) {
    return {
      meta: {
        promise: {
          method: 'POST',
          url: 'organizations',
          data: {
            name: orgName,
            purpose,
            isPublic,
          },
          authenticated: true,
        },
        redirectOnSuccess: { replace: '/organizations-dashboard' },
      },
    };
  },
};
