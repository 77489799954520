import C from '@teamenki/common-constants';

const { USER } = C;

export const name = 'signup';

export const cases = {
  creator({ mail, username, password } = {}) {
    return {
      meta: {
        promise: {
          method: 'POST',
          url: '/auth/signup',
          authenticated: false,
          data: { mail, username, password, signupOnWeb: true },
          saveToken: true,
        },
        feedbackOnError: true,
        feedbackHideDelay: 1000,
        feedbackClearDelay: 300,
        feedbackTag: 'login',
      },
    };
  },
  resolve(state, { payload }) {
    const me = payload.data.user;
    return {
      ...state,
      status: USER.STATUS.LOGGEDIN,
      me,
    };
  },
};
