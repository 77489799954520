export const name = 'changeMemberOrgRole';

export const cases = {
  creator({ organizationId, memberId, role } = {}) {
    return {
      meta: {
        promise: {
          method: 'PATCH',
          url: `/organizations/${encodeURIComponent(
            organizationId
          )}/members/${encodeURIComponent(memberId)}`,
          data: { role },
          authenticated: true,
        },
      },
    };
  },
  resolve(state) {
    return {
      ...state,
      shouldUpdateOrg: true,
    };
  },
};
