import { isNodeProd } from '@teamenki/common-env';

export default function getActionWithOriginCreator() {
  if (isNodeProd) {
    return function action(actionToDispatch) {
      return actionToDispatch;
    };
  }
  return function actionWithOrigin(actionToDispatch, originalAction) {
    return {
      ...actionToDispatch,
      meta: {
        ...actionToDispatch.meta,
        origin: originalAction,
      },
    };
  };
}
