import C from '@teamenki/common-constants';

const { USER } = C;

export const name = 'signupWithProvider';

export const cases = {
  creator({ token } = {}) {
    return {
      meta: {
        promise: {
          method: 'POST',
          url: '/auth/social-signup',
          saveToken: true,
          authenticated: false,
          data: {
            token,
            signupOnWeb: true,
          },
        },
        redirectOnSuccess: { clearHistory: true, location: '/' },
      },
    };
  },
  reducer(state) {
    return {
      ...state,
      status: USER.STATUS.UNKNOWN,
    };
  },
  resolve(state, { payload }) {
    const me = payload.data.user;
    return {
      ...state,
      status: USER.STATUS.LOGGEDIN,
      me,
    };
  },
  reject(state) {
    return {
      ...state,
      status: USER.STATUS.UNAUTHORIZED,
    };
  },
};
