import {
  isRejectedAction,
  isResolvedAction,
  isThenAction,
} from 'redux-optimist-promise';
import has from 'lodash.has';
import uuid from 'uuid/v4';
import { feedback as feedbackDuck } from '../ducks';
import { getActionWithOriginCreator } from './helpers';

const {
  feedbackShowError,
  feedbackShowSuccess,
  feedbackHide,
  feedbackClear,
} = feedbackDuck.actionNames;

const origin = getActionWithOriginCreator();

export default function feedback({ dispatch }) {
  return next => action => {
    const result = next(action);

    const extractPayload = getPayloadExtractor(action);

    const asyncDispatch = ({ type, payload } = {}, ms) =>
      new Promise(resolve =>
        setTimeout(
          () =>
            resolve(
              dispatch(
                origin(
                  {
                    type,
                    payload,
                  },
                  action
                )
              )
            ),
          ms
        )
      );

    if (
      !isThenAction(action.type) &&
      has(action, 'meta.feedbackDirectly') &&
      !action.meta.skipOptimist
    ) {
      asyncDispatch({
        type: feedbackShowSuccess,
        payload: extractPayload('feedbackDirectly'),
      });
    } else if (
      isResolvedAction(action.type) &&
      has(action, 'meta.feedbackOnSuccess')
    ) {
      asyncDispatch({
        type: feedbackShowSuccess,
        payload: extractPayload('feedbackOnSuccess'),
      });
    } else if (
      isRejectedAction(action.type) &&
      has(action, 'meta.feedbackOnError')
    ) {
      asyncDispatch({
        type: feedbackShowError,
        payload: extractPayload('feedbackOnError'),
      });
    }

    if ([feedbackShowSuccess, feedbackShowError].includes(action.type)) {
      // eslint-disable-next-line promise/catch-or-return
      asyncDispatch(
        {
          type: feedbackHide,
          payload: {
            id: action.payload.id,
          },
        },
        action.payload.hideDelay
      ).then(() =>
        asyncDispatch(
          {
            type: feedbackClear,
            payload: {
              id: action.payload.id,
            },
          },
          action.payload.clearDelay
        )
      );
    }

    return result;
  };
}

function getPayloadExtractor(action) {
  function messageExtractor(prop) {
    const message =
      typeof action.meta[prop] === 'function'
        ? action.meta[prop](action)
        : action.meta[prop];

    if (prop === 'feedbackOnSuccess') {
      if (message === true) {
        return has(action, 'payload.data.message')
          ? action.payload.data.message
          : 'success';
      }
      return message;
    }

    if (prop === 'feedbackOnError') {
      if (message === true) {
        if (action.payload.response) {
          if (typeof action.payload.response.data === 'string') {
            return action.payload.response.data;
          }
          if (typeof action.payload.response.data.message === 'object') {
            return action.payload.response.data.message.message;
          }
          return action.payload.response.data.message;
        }
        if (action.payload.message) {
          return action.payload.message;
        }
        if (typeof action.payload.data.message === 'object') {
          return action.payload.data.message.message;
        }
        return action.payload.data.message;
      }
      return message;
    }

    return message;
  }

  const { feedbackHideDelay, feedbackClearDelay } = getFeedbackDelay(action);

  return function payloadExtractor(prop) {
    return {
      ...feedbackDuck.feedbackState,
      id: uuid(),
      hideDelay: feedbackHideDelay,
      clearDelay: feedbackClearDelay,
      message: messageExtractor(prop),
      tag: getFeedbackTag(action),
    };
  };
}

function getFeedbackTag(action) {
  return has(action, 'meta.feedbackTag')
    ? action.meta.feedbackTag
    : feedbackDuck.feedbackState.tag;
}

function getFeedbackDelay(action) {
  if (has(action, 'meta.feedbackDelay')) {
    const delay = action.meta.feedbackDelay;
    return {
      feedbackClearDelay: delay,
      feedbackHideDelay: delay,
    };
  }

  const feedbackClearDelay = has(action, 'meta.feedbackClearDelay')
    ? action.meta.feedbackClearDelay
    : feedbackDuck.feedbackState.clearDelay;

  const feedbackHideDelay = has(action, 'meta.feedbackHideDelay')
    ? action.meta.feedbackHideDelay
    : feedbackDuck.feedbackState.hideDelay;

  return {
    feedbackClearDelay,
    feedbackHideDelay,
  };
}
