import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as validators from '@teamenki/common-validators';
import { auth as authDuck } from '../../redux/ducks';
import AuthWrapper from './AuthWrapper';
import { Button, Form, theme } from '../styles';

const PasswordStatusMessage = styled.p`
  color: ${theme.colors.error};
  font-size: 1rem;
  min-height: 18px;
  margin: 6px 0;
`;

class ResetPassword extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    token: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    resetPassword: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
  };

  state = {
    password: '',
    passwordRepeat: '',
    passwordsMatch: false,
    passwordIsValid: false,
    passwordStatusMessage: '',
  };

  handlePassword = ({ target: { value: password } }) => {
    const { valid, message } = validators.password.validate(password);
    this.setState(prevState => ({
      password,
      passwordsMatch: prevState.passwordRepeat === password,
      passwordIsValid: valid,
      passwordStatusMessage: message,
    }));
  };

  handlePasswordRepeat = ({ target: { value: passwordRepeat } }) => {
    this.setState(prevState => ({
      passwordRepeat,
      passwordsMatch: prevState.password === passwordRepeat,
    }));
  };

  handleResetPassword = e => {
    e.preventDefault();
    if (this.state.passwordsMatch && this.state.passwordIsValid) {
      return this.props
        .resetPassword({
          email: this.props.email,
          token: this.props.token,
          password: this.state.password,
        })
        .then(this.props.onSuccess);
    }
    return false;
  };

  renderResetPasswordForm() {
    return (
      <Form onSubmit={this.handleResetPassword}>
        <fieldset
          disabled={this.props.isLoading}
          aria-busy={this.props.isLoading}
        >
          {!this.props.token && <div className="error">Invalid token</div>}
          {!this.props.email ? (
            <div className="error">User not found</div>
          ) : (
            <div>
              <h2>
                Reset password for{' '}
                <span className="email">{this.props.email}</span>
              </h2>
              <label htmlFor="password">
                Password
                <input
                  type="password"
                  id="password"
                  minLength="8"
                  required
                  value={this.state.password}
                  onChange={this.handlePassword}
                />
              </label>
              <PasswordStatusMessage
                disabled={this.state.passwordStatusMessage}
              >
                {this.state.passwordStatusMessage}
              </PasswordStatusMessage>
              <label htmlFor="passwordRepeat">
                Confirm Password
                <input
                  type="password"
                  id="passwordRepeat"
                  minLength="8"
                  required
                  value={this.state.passwordRepeat}
                  onChange={this.handlePasswordRepeat}
                />
              </label>
              <Button
                type="submit"
                disabled={
                  !this.state.password ||
                  !this.state.passwordRepeat ||
                  !this.state.passwordsMatch
                }
                onClick={this.handleResetPassword}
              >
                Reset Password
              </Button>
            </div>
          )}
        </fieldset>
      </Form>
    );
  }

  render() {
    return (
      <AuthWrapper>
        {this.renderResetPasswordForm()}
        <Link to="/login">I remember my password</Link>
      </AuthWrapper>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  isLoading: auth.meta.resetPassword.isLoading,
});

export default connect(
  mapStateToProps,
  authDuck.actionCreators
)(ResetPassword);
