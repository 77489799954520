import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import C from '@teamenki/common-constants';
import { organization as organizationDuck } from '../../redux/ducks';

import { Button } from '../styles';
import Loader from '../Loader';
import { RadioButtonGroup } from '../RadioButton';

const organizationPurposes = C.ORGANIZATION.PURPOSES.map(purpose => ({
  label: purpose,
  value: purpose,
}));

const organizationPrivacy = [
  {
    label: 'Public',
    value: true,
  },
  {
    label: 'Private',
    value: false,
  },
];

class CreateOrganization extends Component {
  static propTypes = {
    checkOrgNameAvailability: PropTypes.func.isRequired,
    createOrg: PropTypes.func.isRequired,
    isCheckingOrgNameAvailability: PropTypes.bool.isRequired,
    isOrgNameAvailable: PropTypes.bool.isRequired,
  };

  state = {
    orgName: '',
    orgPurpose: organizationPurposes[0].value,
    isOrgPublic: true,
  };

  handleOrgNameChange = ({ target: { value } }) => {
    this.setState(
      {
        orgName: value,
      },
      () => {
        if (this.state.orgName.length > 0) {
          this.props.checkOrgNameAvailability({ orgName: this.state.orgName });
        }
      }
    );
  };

  canCreateOrganization = () =>
    this.state.orgName.length !== 0 &&
    this.props.isOrgNameAvailable &&
    !this.props.isCheckingOrgNameAvailability;

  handlePurposeChange = value => {
    this.setState({
      orgPurpose: value,
    });
  };

  handlePrivacyChange = value => {
    this.setState({
      isOrgPublic: value,
    });
  };

  handleCreateOrganization = e => {
    e.preventDefault();
    this.props.createOrg({
      name: this.state.orgName,
      purpose: this.state.orgPurpose,
      isPublic: this.state.isOrgPublic,
    });
  };

  renderAvailabilityCheck = () => {
    if (this.state.orgName) {
      if (this.props.isCheckingOrgNameAvailability) {
        return <Loader />;
      }
      if (!this.props.isOrgNameAvailable) {
        return (
          <div style={{ padding: '10px', color: 'red' }}>
            {' '}
            This name is not available{' '}
          </div>
        );
      }
    }
    return <div style={{ margin: '25px' }} />;
  };

  render() {
    return (
      <div>
        <div>
          <h4>Create Organization</h4>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <input
              type="text"
              placeholder="Organization name"
              style={
                this.state.orgName && !this.props.isOrgNameAvailable
                  ? { borderColor: 'red' }
                  : {}
              }
              value={this.state.orgName}
              onChange={this.handleOrgNameChange}
            />
            {this.renderAvailabilityCheck()}
          </div>
        </div>
        <div>
          <h4> Organization purpose </h4>
          <RadioButtonGroup
            items={organizationPurposes}
            onChange={this.handlePurposeChange}
          />
        </div>
        <div>
          <h4> Organization privacy </h4>
          <RadioButtonGroup
            items={organizationPrivacy}
            onChange={this.handlePrivacyChange}
          />
          <p>
            {this.state.isOrgPublic
              ? 'This team is public. Anyone can search, find and join this team.'
              : "This team is private. It won't show up in the team search and only the invited ones can join."}
          </p>
        </div>
        <Button
          disabled={!this.canCreateOrganization()}
          onClick={this.handleCreateOrganization}
        >
          Create organization
        </Button>
      </div>
    );
  }
}

const mapStateToProps = ({ organization }) => ({
  isCheckingOrgNameAvailability:
    organization.meta.checkOrgNameAvailability.isLoading,
  isOrgNameAvailable: organization.isOrgNameAvailable,
});

export default connect(
  mapStateToProps,
  organizationDuck.actionCreators
)(CreateOrganization);
