export const name = 'getImages';

export const cases = {
  creator() {
    return {
      meta: {
        promise: {
          method: 'GET',
          url: '/content-images',
          authenticated: true,
        },
      },
    };
  },
  resolve(state, { payload }) {
    const { images } = payload.data;
    return {
      ...state,
      images,
    };
  },
};
