export const name = 'removePrivateContentRepo';

export const cases = {
  creator({ orgId } = {}) {
    return {
      meta: {
        promise: {
          method: 'DELETE',
          url: `/organizations/${encodeURIComponent(orgId)}/repo/`,
          authenticated: true,
        },
        feedbackOnSuccess:
          'Successfully archived the linked private content repository',
        feedbackOnError: `Error archiving the link private content repository. Please try again.`,
      },
    };
  },
};
