import * as actions from './actions';
import createDuck from '../create-duck';

const initialState = {
  githubAccessToken: '',
  githubUser: {},
  githubOrganizations: [],
  githubOrganizationData: {
    totalRepositories: 0,
    repositories: [],
    query: {
      accessError: false,
      lastCursor: null,
      lastPage: false,
    },
  },
};

const duck = createDuck(
  'private-content',
  initialState,
  Object.values(actions)
);

export const { reducer, actionCreators, actionNames } = duck;
